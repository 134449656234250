import { EGeoObjectType, GeoObjectFullFragment } from "@rsonav/protocol";
import { ISwitchBarItem } from "..";

export interface IGeoObjectBlockProps {
	geoObject: Partial<GeoObjectFullFragment> & { Id: string };
	refetchObject?: () => void;
}

export const objectTypeSelectorItems: ISwitchBarItem<EGeoObjectType>[] = [
	{ labelKey: "geo_objectTypeContainers", value: EGeoObjectType.Containers, className: "containers" },
	{ labelKey: "geo_objectTypeStationary", value: EGeoObjectType.Stationary, className: "stationary" },
	{ labelKey: "geo_objectTypeTemporary", value: EGeoObjectType.Temporary, className: "temporary" },
];
