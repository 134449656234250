import React from "react";

import { IGeoObjectBlockProps } from "./types";
import locale from "../../../helpers/strings";
import { Box, Typography } from "@material-ui/core";
import { recycledTypesList } from "../../../helpers/recycledTypesList";

export const RecycledTypes = (props: IGeoObjectBlockProps): JSX.Element | null => {
	if (!props.geoObject.RecycledType?.length) {
		return null;
	}

	return (
		<Box className="info-block">
			<Typography variant="h5" gutterBottom>
				{locale.geo_recycledTypes}
			</Typography>
			<Typography variant="body2">
				{props.geoObject.RecycledType.filter((type) => recycledTypesList[type.Type])
					.map((type) => locale[recycledTypesList[type.Type].localeKey])
					.sort((a, b) => (a > b ? 1 : -1))
					.join(", ")}
			</Typography>
		</Box>
	);
};
