import React, { ChangeEvent, PropsWithChildren, useState } from "react";
import {
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	LinearProgress,
	Tab,
	Tabs,
	Typography,
} from "@material-ui/core";
import locale from "../../../helpers/strings";
import { Close } from "@material-ui/icons";
import useAppContext from "../../../contexts/AppContext";
import { RegionsListTab, UpsertAboutTab, UsersListTab } from "./tabs";

export interface IAdminDialogProps {
	open: boolean;
	onClose: () => void;
}

interface ITabPanelProps {
	index: number;
	value: number;
}

const TabPanel = (props: PropsWithChildren<ITabPanelProps>) => {
	const { children, value, index, ...other } = props;

	return (
		<div role="tabpanel" className="tab-pannel" hidden={value !== index} id={`simple-tabpanel-${index}`} {...other}>
			{value === index && children}
		</div>
	);
};

export const AdminDialog = (props: IAdminDialogProps) => {
	const { open, onClose } = props;

	const { login } = useAppContext();

	const [loading, setLoading] = useState(false);
	const [tabIndex, setTabIndex] = React.useState(0);

	const setNewTabIndex = (_event: ChangeEvent<{}>, newIndex: any) => {
		setTabIndex(newIndex as number);
	};
	if (!login) {
		return null;
	}

	return (
		<Dialog onClose={onClose} className="admin-dialog" open={open} maxWidth="xl" fullWidth>
			<DialogTitle>
				<Grid container justify="space-between" alignItems="center">
					<Typography variant="h6">{locale.web_menuAdmin}</Typography>
					<IconButton aria-label="close" onClick={onClose}>
						<Close />
					</IconButton>
				</Grid>
				<Tabs value={tabIndex} onChange={setNewTabIndex} indicatorColor="primary">
					<Tab label={locale.web_usersList} />
					<Tab label={locale.web_regionsList} />
					<Tab label={locale.about_title} />
				</Tabs>
			</DialogTitle>
			<div className="progress-container">{loading && <LinearProgress variant="indeterminate" />}</div>
			<DialogContent dividers>
				<TabPanel value={tabIndex} index={0}>
					<UsersListTab onLoading={setLoading} />
				</TabPanel>
				<TabPanel value={tabIndex} index={1}>
					<RegionsListTab onLoading={setLoading} />
				</TabPanel>
				<TabPanel value={tabIndex} index={2}>
					<UpsertAboutTab onLoading={setLoading} />
				</TabPanel>
			</DialogContent>
		</Dialog>
	);
};
