import { Button, CircularProgress } from "@material-ui/core";
import { GetMyFavoritesDocument, useRemoveFavoriteMutation, useUpsertFavoriteMutation } from "@rsonav/protocol";
import React from "react";
import useAppContext from "../../../contexts/AppContext";
import locale from "../../../helpers/strings";
import { IGeoObjectBlockProps } from "./types";

export const FavoriteButton = (props: IGeoObjectBlockProps): JSX.Element | null => {
	const { login, geoPosition } = useAppContext();

	const [upsertFavorite, upsertState] = useUpsertFavoriteMutation();
	const [removeFavorite, removeState] = useRemoveFavoriteMutation();

	if (!login) {
		return null;
	}

	const onClick = () => {
		if (!props.geoObject.Id) {
			return;
		}
		if (props.geoObject.FavoriteId) {
			removeFavorite({
				variables: { id: props.geoObject.FavoriteId },
				refetchQueries: [
					{
						query: GetMyFavoritesDocument,
						variables: { lat: geoPosition?.coords.latitude, long: geoPosition?.coords.longitude },
					},
				],
			})
				.then(() => {
					if (props.refetchObject) {
						props.refetchObject();
					}
				})
				.catch((reason) => {
					console.warn("Error removing favorite:", reason);
				});
		} else {
			upsertFavorite({
				variables: { geoObjectId: props.geoObject.Id },
				refetchQueries: [
					{
						query: GetMyFavoritesDocument,
						variables: { lat: geoPosition?.coords.latitude, long: geoPosition?.coords.longitude },
					},
				],
			})
				.then(() => {
					if (props.refetchObject) {
						props.refetchObject();
					}
				})
				.catch((reason) => {
					console.warn("Error upserting favorite:", reason);
				});
		}
	};

	return (
		<Button
			className="favorites-button"
			endIcon={
				upsertState.loading || removeState.loading ? (
					<CircularProgress size={20} color="inherit" />
				) : (
					<i className={`icon icon-${props.geoObject.FavoriteId ? "heart-fill" : "heart"}`} />
				)
			}
			onClick={onClick}
			disabled={upsertState.loading || removeState.loading}
			fullWidth
			variant={props.geoObject.FavoriteId ? "outlined" : "contained"}
			color="primary"
		>
			{props.geoObject.FavoriteId ? locale.profile_favoritesRemove : locale.profile_favoritesAdd}
		</Button>
	);
};
