import { AttachmentFullFragment, EAttachmentType, useGetObjectAttachmentsLazyQuery } from "@rsonav/protocol";
import React, { useEffect } from "react";

import { IGeoObjectBlockProps } from "./types";
import Slider from "react-slick";
import { Box, Dialog } from "@material-ui/core";

interface IAttachmentItemProps {
	attachment: AttachmentFullFragment;
	full?: boolean;
	onClick?: () => void;
}
const AttachmentItem = (props: IAttachmentItemProps) => {
	return (
		<div onClick={props.onClick} className="attachment-container">
			<img
				src={(!props.full && props.attachment.PreviewUrl) || props.attachment.Url}
				alt={props.attachment.FileName}
			/>
		</div>
	);
};

interface GalleryDialogProps {
	open: boolean;
	attachments: AttachmentFullFragment[];
	onClose: () => void;
	index?: number;
}

const GalleryDialog = (props: GalleryDialogProps) => {
	const { onClose, attachments, open, index } = props;

	return (
		<Dialog onClose={onClose} className="gallery-dialog" open={open} fullWidth>
			<Slider infinite dots className="object-gallery" initialSlide={index}>
				{attachments.map((attachment) => (
					<AttachmentItem attachment={attachment} key={attachment.Id} full />
				))}
			</Slider>
		</Dialog>
	);
};

export const Gallery = (props: IGeoObjectBlockProps): JSX.Element | null => {
	const [getObjectAttachments, { data }] = useGetObjectAttachmentsLazyQuery({
		fetchPolicy: "cache-and-network",
	});

	const [open, setOpen] = React.useState(false);
	const [currentIndex, setCurrentIndex] = React.useState(0);

	useEffect(() => {
		getObjectAttachments({
			variables: {
				objectId: props.geoObject.Id,
				type: EAttachmentType.GeoObjectGallery,
			},
		});
	}, [getObjectAttachments, props.geoObject.Id]);

	if (!data?.getObjectAttachments.length) {
		return null;
	}

	const openFull = (index: number) => {
		setCurrentIndex(index);
		setOpen(true);
	};

	const closeFull = () => {
		setOpen(false);
	};

	return (
		<Box>
			<Slider
				slidesToShow={Math.min(3, data.getObjectAttachments.length)}
				infinite
				dots
				className="object-gallery"
				initialSlide={currentIndex}
				centerMode
				centerPadding="-32px"
			>
				{data.getObjectAttachments.map((attachment, idx) => (
					<AttachmentItem attachment={attachment} key={attachment.Id} onClick={() => openFull(idx)} />
				))}
			</Slider>
			<GalleryDialog
				index={currentIndex}
				attachments={data.getObjectAttachments}
				onClose={closeFull}
				open={open}
			/>
		</Box>
	);
};
