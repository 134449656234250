import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { IGeoObjectBlockProps } from "./types";
import locale from "../../../helpers/strings";
import { EGeoObjectType } from "@rsonav/protocol";

export const Containers = (props: IGeoObjectBlockProps) => {
	if (props.geoObject.Type === EGeoObjectType.Stationary) {
		return null;
	}
	return (
		<Grid container justify="space-between" className="containers">
			<Grid item xs={6}>
				<Typography variant="subtitle1">{locale.geo_regularContainers}</Typography>
				<Typography variant="h2">{props.geoObject.Containers}</Typography>
			</Grid>
			<Grid item xs={6}>
				<Typography variant="subtitle1">{locale.geo_specialContainers}</Typography>
				<Typography variant="h2">{props.geoObject.SpecialContainers}</Typography>
			</Grid>
		</Grid>
	);
};
