import React, { useEffect, useState } from "react";
import {
	Box,
	Button,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	useMediaQuery,
} from "@material-ui/core";
import { useHistory, useLocation, useParams } from "react-router-dom";
import locale from "../../helpers/strings";
import useAppContext from "../../contexts/AppContext";
import { LinkWithParams, AdminDialog, ViewGeo, ViewPolicyDialog } from ".";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import { mobileThreshold } from "../../helpers/constants";
import { ELoginRole, RegionFullFragment, useSetHomeRegionMutation } from "@rsonav/protocol";

export interface IStandartViewProps {
	className?: string;
	hideHeader?: boolean;
	hideMenu?: boolean;
	hideFooter?: boolean;
	header?: React.ReactNode;
	policyOpened?: boolean;
}

export const StandartView = (props: React.PropsWithChildren<IStandartViewProps>) => {
	const {
		login,
		clearSession,
		closePannel,
		editCoords,
		setEditCoords,
		regions,
		curRegion,
		localRegion,
		setLocalRegion,
		setLogin,
	} = useAppContext();
	const { id } = useParams<{ id: string }>();
	const location = useLocation();
	const history = useHistory();
	const mobileScreen = useMediaQuery(mobileThreshold);

	const [viewPolicy, setViewPolicy] = useState<boolean>(false);
	const [viewUsers, setViewUsers] = useState<boolean>(false);
	const [selectRegionOpened, setSelectRegionOpened] = React.useState<HTMLElement | null>(null);

	const [viewGeo, setViewGeo] = useState<boolean>(id !== undefined);

	const [setHomeRegion, { loading }] = useSetHomeRegionMutation();

	useEffect(() => {
		setViewPolicy(!!props.policyOpened);
	}, [props.policyOpened]);

	useEffect(() => {
		setViewGeo(id !== undefined || (editCoords !== undefined && location.pathname.includes("/geo/add")));
	}, [id, editCoords, location.pathname]);

	const closePolicy = () => {
		setViewPolicy(false);
	};

	const closeUsers = () => {
		setViewUsers(false);
	};

	const closeGeo = () => {
		setViewGeo(false);
		setEditCoords(undefined);
		const goToLocation = location.pathname.split("/").slice(0, -2).join("/");
		history.push(goToLocation || "/");
	};

	const openSelectRegion = (event: React.MouseEvent<HTMLElement>) => {
		setSelectRegionOpened(event.currentTarget);
	};

	const closeSelectRegion = () => {
		setSelectRegionOpened(null);
	};

	const updateHomeRegion = (region: RegionFullFragment) => {
		setLocalRegion(region);
		if (!login || login.HomeRegion?.Id === region.Id) {
			return;
		}
		setHomeRegion({ variables: { regionId: region.Id } })
			.then((result) => {
				if (result.data?.setHomeRegion.data) {
					setLogin(result.data?.setHomeRegion.data.Login);
				}
			})
			.catch((err) => {
				console.warn("Error setting home region: ", err);
			});
	};

	const open = Boolean(selectRegionOpened);
	const selectedRegion = login?.HomeRegion || localRegion || curRegion;

	return (
		<>
			<Box className="pannel-content">
				<Box className={props.className ? `content-container ${props.className}` : "content-container"}>
					<Box className="top-navigation">
						{!props.hideHeader && (
							<Grid container justify="space-between" alignItems="center">
								<div className="header">
									<LinkWithParams to="/">
										<div className="logo" />
									</LinkWithParams>
									<div className="name">
										<LinkWithParams to="/">
											<h1>{locale.about_name}</h1>
										</LinkWithParams>
										<Button
											disabled={!regions?.length || loading}
											variant="text"
											size="small"
											onClick={openSelectRegion}
											endIcon={<i className="icon icon-arrow-left" />}
										>
											<span className="button-label">
												{selectedRegion?.Name || locale.profile_homeNotSelected}
											</span>
										</Button>
										{regions && (
											<Menu
												anchorEl={selectRegionOpened}
												keepMounted
												open={open}
												onClose={closeSelectRegion}
												getContentAnchorEl={null}
												anchorOrigin={{
													vertical: "bottom",
													horizontal: "left",
												}}
												transformOrigin={{
													vertical: "top",
													horizontal: "left",
												}}
											>
												{regions.map((region) => (
													<MenuItem
														key={region.Id}
														selected={region.Id === selectedRegion?.Id}
														onClick={() => updateHomeRegion(region)}
													>
														{region.Name}
													</MenuItem>
												))}
											</Menu>
										)}
									</div>
								</div>

								{mobileScreen && (
									<IconButton onClick={closePannel} className="back-button">
										<ChevronLeftIcon color="inherit" />
									</IconButton>
								)}
							</Grid>
						)}
						{!props.hideMenu && (
							<List component="nav">
								<LinkWithParams to="/">
									<ListItem button>
										<ListItemIcon>
											<i className="icon icon-tell" />
										</ListItemIcon>
										<ListItemText primary={locale.news_newsTabTitle} />
									</ListItem>
								</LinkWithParams>
								<LinkWithParams to="/favorites">
									<ListItem button>
										<ListItemIcon>
											<i className="icon icon-heart" />
										</ListItemIcon>
										<ListItemText primary={locale.profile_favorites} />
									</ListItem>
								</LinkWithParams>
								{selectedRegion?.SupportEmail && (
									<LinkWithParams to="/support">
										<ListItem button>
											<ListItemIcon>
												<i className="icon icon-msg" />
											</ListItemIcon>
											<ListItemText primary={locale.profile_goToSupport} />
										</ListItem>
									</LinkWithParams>
								)}
								<LinkWithParams to="/about">
									<ListItem button>
										<ListItemIcon>
											<i className="icon icon-info" />
										</ListItemIcon>
										<ListItemText primary={locale.about_title} />
									</ListItem>
								</LinkWithParams>
								{(login?.Role === ELoginRole.Admin || login?.Role === ELoginRole.Moderator) && (
									<ListItem button onClick={() => setViewUsers(true)}>
										<ListItemIcon>
											<SettingsOutlinedIcon />
										</ListItemIcon>
										<ListItemText primary={locale.web_menuAdmin} />
									</ListItem>
								)}
								{login ? (
									<ListItem button onClick={clearSession}>
										<ListItemIcon>
											<i className="icon icon-out" />
										</ListItemIcon>
										<ListItemText primary={locale.web_menuLogOut} />
									</ListItem>
								) : (
									<LinkWithParams to="/signup">
										<ListItem button>
											<ListItemIcon>
												<i className="icon icon-sing" />
											</ListItemIcon>
											<ListItemText primary={locale.web_menuLogIn} />
										</ListItem>
									</LinkWithParams>
								)}
							</List>
						)}
						{props.header && <Box className="page-header">{props.header}</Box>}
					</Box>
					<Box flex={1}>{props.children}</Box>
				</Box>
				{!props.hideFooter && (
					<Box className="footer-container">
						<a href="https://apps.apple.com/ru/app/id1545604344" target="_blank" rel="noreferrer">
							<img src="/app-store.svg" alt="" />
						</a>
						<a
							href="https://play.google.com/store/apps/details?id=app.rsonav"
							target="_blank"
							rel="noreferrer"
						>
							<img src="/google-play.svg" alt="" />
						</a>
					</Box>
				)}
				<ViewPolicyDialog onClose={closePolicy} open={viewPolicy} />
				<AdminDialog onClose={closeUsers} open={viewUsers} />
			</Box>
			<ViewGeo onClose={closeGeo} open={viewGeo} />
		</>
	);
};
