import { TLocale } from "@rsonav/shared-config";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
	EAttachmentType,
	useUploadAttachmentMutation,
	useGetObjectAttachmentsLazyQuery,
	GetObjectAttachmentsQueryVariables,
	EFileType,
	AttachmentFullFragment,
	useRemoveAttachmentMutation,
} from "@rsonav/protocol";
import locale from "../helpers/strings";
import useAppContext from "../contexts/AppContext";
import { AlertType, DropzoneArea } from "material-ui-dropzone";
import {
	Avatar,
	Box,
	Button,
	Dialog,
	Icon,
	IconButton,
	InputLabel,
	List,
	ListItem,
	ListItemAvatar,
	ListItemSecondaryAction,
	ListItemText,
	Typography,
} from "@material-ui/core";
import Skeleton from "react-loading-skeleton";
import { humanFileSize } from "../helpers/humanFileSize";
import LinkIcon from "@material-ui/icons/Link";
import { copyToClipboard } from "../helpers/copyToClipboard";

export interface IGalleryProps {
	attachmentType: EAttachmentType;
	objectId?: string;
	maxItems?: number;
	readonly?: boolean;
	onUpdate?: (attachments: AttachmentFullFragment[]) => void;
	addFileTip?: keyof TLocale;
	label?: keyof TLocale;
	acceptedFiles?: string[];
	maxFileSize?: number;
	additionalRequirementsText?: keyof TLocale;
	compact?: boolean;
	onLoadingChange?: (loading: boolean) => void;
}

interface IAttachmentItemProps {
	attachment?: AttachmentFullFragment;
	readonly?: boolean;
	afterDelete?: (id: string) => void;
	openFull?: (attachment: AttachmentFullFragment) => void;
	compact?: boolean;
}

interface IGalleryDialogProps {
	open: boolean;
	attachment?: AttachmentFullFragment;
	onClose: () => void;
}
const GalleryDialog = (props: IGalleryDialogProps) => {
	const { onClose, attachment, open } = props;

	if (!attachment) {
		return null;
	}

	return (
		<Dialog onClose={onClose} className="gallery-dialog" open={open}>
			<div className="object-gallery">
				<div className="attachment-container">
					<img src={attachment.Url} alt={attachment.FileName} />
				</div>
			</div>
		</Dialog>
	);
};

const AttachmentItem = (props: IAttachmentItemProps) => {
	const [removeAttachment, removeAttachmentState] = useRemoveAttachmentMutation();

	if (!props.attachment || removeAttachmentState.loading) {
		return (
			<ListItem className="placeholder">
				<ListItemAvatar>
					<Skeleton />
				</ListItemAvatar>
				{!props.compact && <ListItemText primary={<Skeleton />} />}
			</ListItem>
		);
	}

	const removeFile = async () => {
		if (props.attachment) {
			await removeAttachment({ variables: { id: props.attachment.Id } });
			props.afterDelete && props.afterDelete(props.attachment.Id);
		}
	};

	return (
		<ListItem>
			<ListItemAvatar>
				{props.attachment.PreviewUrl ? (
					<Button
						onClick={() =>
							props.attachment?.FileType === EFileType.Image &&
							props.openFull &&
							props.openFull(props.attachment)
						}
						disabled={
							!props.attachment?.Url || props.attachment?.FileType !== EFileType.Image || !props.openFull
						}
					>
						<Avatar alt={props.attachment.FileName} src={props.attachment.PreviewUrl} />
					</Button>
				) : (
					<Icon>
						<i className="icon icon-photo" />
					</Icon>
				)}
			</ListItemAvatar>
			{!props.compact && (
				<ListItemText
					primary={
						<Button
							variant="text"
							size="small"
							title={locale.copyImageUrl}
							endIcon={<LinkIcon color="primary" />}
							onClick={() => props.attachment && copyToClipboard(props.attachment.Url)}
						>
							{props.attachment.FileName}
						</Button>
					}
				/>
			)}
			<ListItemSecondaryAction>
				<IconButton
					onClick={removeFile}
					color={props.compact ? "inherit" : "primary"}
					title={locale.addFileRemove}
				>
					<i className="icon icon-container-map" />
				</IconButton>
			</ListItemSecondaryAction>
		</ListItem>
	);
};

const UploadIcon = (): React.ReactElement => {
	return <Icon className="icon icon-photo" />;
};

export const Gallery = (props: IGalleryProps) => {
	const { objectId, attachmentType, compact, onLoadingChange, onUpdate } = props;

	const [getObjectAttachments, { loading, data }] = useGetObjectAttachmentsLazyQuery({
		fetchPolicy: "cache-and-network",
	});
	const [uploadMutation, uploadFileState] = useUploadAttachmentMutation();

	const { login, sharedConfig } = useAppContext();

	const [file, setFile] = useState<File>();
	const [errorMessage, setErrorMessage] = useState<string>();
	const [opened, setOpened] = useState<AttachmentFullFragment>();

	const variables = useRef<GetObjectAttachmentsQueryVariables>({
		objectId: "-1",
		type: attachmentType,
	});

	if (objectId) {
		variables.current.objectId = objectId;
	} else {
		variables.current.loginId = login?.Id;
	}

	useEffect(() => {
		getObjectAttachments({
			variables: variables.current,
		});
	}, [getObjectAttachments, objectId]);

	const uploadFile = useCallback(
		async (file?: File) => {
			if (!file) {
				return;
			}

			if (!file.name) {
				return;
			}
			onLoadingChange && onLoadingChange(true);
			await uploadMutation({
				variables: {
					file: {
						File: file,
						FileName: file.name,
						FileType: EFileType.Image,
						Type: attachmentType,
						ObjectId: objectId || "-1",
					},
				},
				context: {
					fetchOptions: {
						useUpload: true,
					},
				},
			});

			getObjectAttachments({
				variables: variables.current,
			});
			onLoadingChange && onLoadingChange(false);
		},
		[getObjectAttachments, onLoadingChange, attachmentType, objectId, uploadMutation],
	);

	useEffect(() => {
		setErrorMessage(undefined);
		void uploadFile(file);
	}, [file, uploadFile]);

	useEffect(() => {
		if (data?.getObjectAttachments && onUpdate) {
			onUpdate(data.getObjectAttachments);
		}
	}, [data?.getObjectAttachments, onUpdate]);

	const setMessage = (message: string, type: AlertType) => {
		if (type === "error") {
			setErrorMessage(message);
		} else {
			setErrorMessage(undefined);
		}
	};

	const openFull = (attachment: AttachmentFullFragment) => {
		if (attachment.Url) {
			setOpened(attachment);
		}
	};

	const closeFull = () => {
		setOpened(undefined);
	};

	const itemsCount = data?.getObjectAttachments.length || 0;
	const maxFileSize =
		(props.maxFileSize && Math.min(sharedConfig.attachments.maxFileSize, props.maxFileSize)) ||
		sharedConfig.attachments.maxFileSize;

	const requirementsMessage = [`${locale.numberRangeEnd} ${humanFileSize(maxFileSize)}`];
	props.additionalRequirementsText && requirementsMessage.push(locale[props.additionalRequirementsText]);

	const getDropRejectMessage = (rejectedFile: File, acceptedFiles: string[], maxFileSize: number) => {
		let message: string[] = [];
		if (!acceptedFiles.includes(rejectedFile.type)) {
			message.push(locale.addFileErrorType);
		}
		if (rejectedFile.size > maxFileSize) {
			message.push(locale.addFileErrorSize);
		}
		return message.join(". ");
	};

	const getFileLimitExceedMessage = () => locale.addFileErrorLimit;

	return (
		<Box className="MuiFormControl-marginNormal">
			{props.label && <InputLabel>{locale[props.label]}</InputLabel>}
			<List className={`gallery ${compact ? "compact" : ""}`}>
				{data?.getObjectAttachments.map((attachment) => (
					<AttachmentItem
						key={attachment.Id}
						attachment={attachment}
						afterDelete={() => {
							getObjectAttachments({
								variables: variables.current,
							});
						}}
						openFull={openFull}
						compact={compact}
					/>
				))}
				{!props.readonly &&
					(!props.maxItems || itemsCount < props.maxItems) &&
					(uploadFileState.loading || loading ? (
						<AttachmentItem compact={compact} />
					) : (
						<Box className={errorMessage && "Mui-error"}>
							<DropzoneArea
								acceptedFiles={props.acceptedFiles}
								dropzoneText={locale[props.addFileTip || "addFile"]}
								onChange={(files) => setFile(files[0])}
								filesLimit={1}
								maxFileSize={maxFileSize}
								showAlerts={false}
								showPreviewsInDropzone={false}
								Icon={UploadIcon as any}
								onAlert={setMessage}
								getFileLimitExceedMessage={getFileLimitExceedMessage}
								getDropRejectMessage={getDropRejectMessage}
								disableRejectionFeedback
							/>
							{!!errorMessage && <p className="MuiFormHelperText-root Mui-error">{errorMessage}</p>}
							{!compact && (
								<Typography variant="body2" color="primary">{`${
									locale.addFileRequirements
								}: ${requirementsMessage.join(", ")}.`}</Typography>
							)}
						</Box>
					))}
			</List>
			<GalleryDialog attachment={opened} onClose={closeFull} open={!!opened} />
		</Box>
	);
};
