import React from "react";
import { Box, Typography } from "@material-ui/core";
import locale from "../../../helpers/strings";
import { IGeoObjectBlockProps } from "./types";
import moment from "moment";

export const Dates = (props: IGeoObjectBlockProps): JSX.Element | null => {
	if (!props.geoObject.StartAt && !props.geoObject.EndAt) {
		return null;
	}

	const dateParts: string[] = [];

	if (props.geoObject.StartAt) {
		dateParts.push(
			`${locale.timeRangeStart} ${moment.utc(props.geoObject.StartAt).local().format("DD MMMM YYYY")}`,
		);
	}

	if (props.geoObject.EndAt) {
		dateParts.push(`${locale.timeRangeEnd} ${moment.utc(props.geoObject.EndAt).local().format("DD MMMM YYYY")}`);
	}

	const dateString = dateParts.join(" ");
	return (
		<Box className="info-block">
			<Typography variant="h5" gutterBottom>
				{locale.geo_datesTitle}:
			</Typography>
			<Typography variant="body2">{dateString.charAt(0).toUpperCase() + dateString.slice(1)}</Typography>
		</Box>
	);
};
