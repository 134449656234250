import React from "react";
import { Box, Typography } from "@material-ui/core";
import locale from "../../../helpers/strings";
import { IGeoObjectBlockProps } from "./types";

export const WorkingHours = (props: IGeoObjectBlockProps): JSX.Element | null => {
	if (!props.geoObject.WorkingHours) {
		return null;
	}

	return (
		<Box className="info-block">
			<Typography variant="h5" gutterBottom>
				{locale.geo_workingHours}:
			</Typography>
			<Typography variant="body2">{props.geoObject.WorkingHours}</Typography>
		</Box>
	);
};
