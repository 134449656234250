import React from "react";
import { ArticleForListFragment, EArticleType } from "@rsonav/protocol";
import { Box, Chip, Grid, Typography } from "@material-ui/core";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import locale from "../../helpers/strings";
import { articleTypes } from "./constants";

export interface INewsListItemProps {
	onClick?: (article: ArticleForListFragment) => void;
	article?: ArticleForListFragment;
	showBadge?: boolean;
	hidePromo?: boolean;
}

export const NewsListItem = (props: INewsListItemProps) => {
	if (!props.article) {
		return (
			<Grid item className="news-item placeholder">
				<Box className="container">
					<Box className="promo">
						<Skeleton />
					</Box>
					<Box className="content">
						<Box>
							<Typography variant="body2">
								<Skeleton />
							</Typography>
							<Typography variant="body2">
								<Skeleton />
							</Typography>
						</Box>
						<Typography variant="subtitle1">
							<Skeleton />
						</Typography>
					</Box>
				</Box>
			</Grid>
		);
	}

	return (
		<Grid item className="news-item" onClick={() => props.article && props.onClick && props.onClick(props.article)}>
			<Box className={`container ${props.article.Important && "big"}`}>
				{props.article.Type === EArticleType.News && (
					<Box className="promo">
						{props.article.PromoAttachment?.PreviewUrl && (
							<img src={props.article.PromoAttachment.PreviewUrl} alt={props.article.Header} />
						)}
					</Box>
				)}
				<Box className="content">
					{props.article.Type !== EArticleType.News && (
						<Chip label={locale[articleTypes[props.article.Type]]} className="article-badge" />
					)}
					<Typography variant="body2">{props.article.Header}</Typography>
					{props.article.CreatedAt && props.article.Type === EArticleType.News && (
						<Typography variant="subtitle1">
							{moment.utc(props.article.CreatedAt).utcOffset(180, true).local().format("DD MMMM YYYY")}
						</Typography>
					)}
				</Box>
			</Box>
		</Grid>
	);
};
