import { Box, IconButton, Link, Typography } from "@material-ui/core";
import React from "react";
import { geoOrgLabel } from "../../../helpers/geoOrgLabel";
import { IGeoObjectBlockProps } from "./types";

export const OrgInfo = (props: IGeoObjectBlockProps): JSX.Element | null => {
	if (!props.geoObject.Type?.length || !props.geoObject.Org) {
		return null;
	}

	return (
		<Box className="info-block org">
			<Box>
				<Typography variant="h5" gutterBottom>
					{geoOrgLabel(props.geoObject.Type)}
				</Typography>
				<Typography variant="body2">{props.geoObject.Org}</Typography>
				{!!props.geoObject.Phone && (
					<Typography variant="body2">
						<Link variant="body2" href={`tel:${props.geoObject.Phone}`}>
							{props.geoObject.Phone}
						</Link>
					</Typography>
				)}
				{!!props.geoObject.URL && (
					<Typography variant="body2">
						<Link
							variant="body2"
							target="_blank"
							rel="noreferrer"
							href={
								props.geoObject.URL.startsWith("http")
									? props.geoObject.URL
									: `http://${props.geoObject.URL}`
							}
						>
							{props.geoObject.URL}
						</Link>
					</Typography>
				)}
				{!!props.geoObject.Email && (
					<Typography variant="body2">
						<Link variant="body2" href={`mailto:${props.geoObject.Email}`}>
							{props.geoObject.Email}
						</Link>
					</Typography>
				)}
			</Box>
			<Box>
				{!!props.geoObject.Phone && (
					<IconButton href={`tel:${props.geoObject.Phone}`}>
						<i className="icon icon-phone" />
					</IconButton>
				)}
			</Box>
		</Box>
	);
};
