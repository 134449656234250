import React, { useEffect, useRef, useState } from "react";

import "./App.scss";

import { ApolloProvider, ApolloClient, NormalizedCacheObject } from "@apollo/client";
import createApolloClient from "./helpers/apollo";
import { Splash } from "./views";
import { UseAppContextProvider } from "./contexts/AppContext";
import Router from "./Router";
import { loadConfig } from "@rsonav/shared-config";
import { YMaps } from "react-yandex-maps";
import { Box, Drawer, IconButton, ThemeProvider, useMediaQuery } from "@material-ui/core";
import { ObjectsMap } from "./components";
import { BrowserRouter } from "react-router-dom";
import { theme } from "./helpers/appTheme";
import { useCookies } from "react-cookie";
import moment from "moment";
import "moment/locale/ru";
import locale from "./helpers/strings";
import MenuIcon from "@material-ui/icons/Menu";
import { mobileThreshold } from "./helpers/constants";

const currentLocale = locale.getLanguage();
moment.locale(currentLocale);

const App = () => {
	const [cookies, setCookie, removeCookie] = useCookies();

	const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();
	const [pannelOpened, setPannelOpened] = useState<boolean>(false);
	const authToken = useRef<string | null>(cookies.token);
	const errorCode = useRef<string | null>();

	const sharedConfig = loadConfig(process.env.REACT_APP_CHANNEL || "local");

	const mobileScreen = useMediaQuery(mobileThreshold);

	useEffect(() => {
		if (cookies.token) {
			setCookie("token", cookies.token, { expires: moment().add(2, "weeks").toDate() });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getAuthToken = () => {
		return authToken.current;
	};

	const updateAuthToken = (token: string | null) => {
		authToken.current = token;
		if (token) {
			setCookie("token", token, { expires: moment().add(2, "weeks").toDate() });
		} else {
			logout();
		}
	};

	const updateErrorCode = (code?: string | null) => {
		errorCode.current = code;
	};

	const getErrorCode = () => {
		return errorCode.current;
	};

	const logout = async () => {
		removeCookie("token");

		if (client != null) {
			await client.clearStore();
			await client.cache.reset();
		}
	};

	if (!client) {
		setClient(
			createApolloClient({
				sharedConfig,
				onErrorLogout: logout,
				getAuthToken: getAuthToken,
				setErrorCode: updateErrorCode,
			}),
		);

		return <Splash />;
	}

	const closePannel = () => {
		setPannelOpened(false);
	};

	const openPannel = () => {
		setPannelOpened(true);
	};

	return (
		<ApolloProvider client={client}>
			<YMaps query={{ apikey: "d629f99a-fc69-4996-818c-18f5efca88b0" }}>
				<UseAppContextProvider
					token={authToken.current}
					updateAuthToken={(token) => updateAuthToken(token)}
					updateErrorCode={(code) => updateErrorCode(code)}
					getErrorCode={getErrorCode}
					closePannel={closePannel}
				>
					<ThemeProvider theme={theme}>
						<BrowserRouter>
							<Box className="app-container">
								<IconButton
									color="inherit"
									onClick={openPannel}
									className={`menu-button ${pannelOpened ? "opened" : "closed"})`}
								>
									<MenuIcon />
								</IconButton>
								<Drawer
									className="left-pannel"
									variant={mobileScreen ? "persistent" : "permanent"}
									anchor="left"
									PaperProps={{ className: "left-pannel-paper" }}
									open={pannelOpened}
								>
									<Router />
								</Drawer>
								<ObjectsMap />
							</Box>
						</BrowserRouter>
					</ThemeProvider>
				</UseAppContextProvider>
			</YMaps>
		</ApolloProvider>
	);
};

export default App;
