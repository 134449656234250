import React from "react";
import { CircularProgress, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@material-ui/core";
import locale from "../../../helpers/strings";
import { IGeoObjectBlockProps } from "./types";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import useAppContext from "../../../contexts/AppContext";
import { EGeoObjectStatus, ELoginRole, useUpsertGeoObjectMutation } from "@rsonav/protocol";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import EditIcon from "@material-ui/icons/Edit";

export const GeoActions = (props: IGeoObjectBlockProps): JSX.Element | null => {
	const { login, updateForceRefetchTime, setEditCoords } = useAppContext();
	const [upsertObject, { loading }] = useUpsertGeoObjectMutation();

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const setStatus = (status: EGeoObjectStatus) => {
		if (
			props.geoObject.Type &&
			props.geoObject.Lat !== undefined &&
			props.geoObject.Long !== undefined &&
			props.geoObject.Address &&
			props.geoObject.AllTime !== undefined &&
			props.geoObject.Containers !== undefined &&
			props.geoObject.SpecialContainers !== undefined &&
			props.geoObject.Region
		) {
			const input = {
				Id: props.geoObject.Id,
				Type: props.geoObject.Type,
				Status: status,
				Lat: props.geoObject.Lat,
				Long: props.geoObject.Long,
				Address: props.geoObject.Address,
				AllTime: props.geoObject.AllTime,
				Containers: props.geoObject.Containers,
				SpecialContainers: props.geoObject.SpecialContainers,
				RegionName: props.geoObject.Region.Name,
			};

			upsertObject({ variables: { data: input } }).then(() => {
				if (props.refetchObject) {
					props.refetchObject();
					updateForceRefetchTime();
				}
			});
		}
	};

	const approveObject = () => {
		handleClose();
		setStatus(EGeoObjectStatus.Approved);
	};

	const rejectObject = () => {
		handleClose();
		setStatus(EGeoObjectStatus.Rejected);
	};

	const editObject = () => {
		handleClose();
		props.geoObject.Lat && props.geoObject.Long && setEditCoords([props.geoObject.Lat, props.geoObject.Long]);
	};

	if (login?.Role !== ELoginRole.Admin && login?.Role !== ELoginRole.Moderator) {
		return null;
	}

	return (
		<div>
			<IconButton onClick={handleClick} disabled={loading}>
				{loading ? <CircularProgress color="inherit" size={16} /> : <MoreVertIcon />}
			</IconButton>
			<Menu anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={handleClose}>
				{(props.geoObject.Status === EGeoObjectStatus.New ||
					props.geoObject.Status === EGeoObjectStatus.Rejected) && (
					<MenuItem onClick={approveObject}>
						<ListItemIcon>
							<ThumbUpIcon fontSize="small" />
						</ListItemIcon>
						<ListItemText primary={locale.approve} />
					</MenuItem>
				)}
				{(props.geoObject.Status === EGeoObjectStatus.New ||
					props.geoObject.Status === EGeoObjectStatus.Approved) && (
					<MenuItem onClick={rejectObject}>
						<ListItemIcon>
							<ThumbDownIcon fontSize="small" />
						</ListItemIcon>
						<ListItemText primary={locale.reject} />
					</MenuItem>
				)}
				<MenuItem onClick={editObject}>
					<ListItemIcon>
						<EditIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText primary={locale.editAction} />
				</MenuItem>
			</Menu>
		</div>
	);
};
