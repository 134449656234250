import React from "react";
import { generatePath, Link, LinkProps, useLocation, useParams } from "react-router-dom";

export const LinkWithParams = (props: LinkProps) => {
	const { to, ...linkProps } = props;
	let { id, popup } = useParams<{ popup?: string; id?: string }>();
	const location = useLocation();

	let modifiedTo = typeof to === "function" ? to(location) : to;

	if (id && popup) {
		const pathSuffix = generatePath("/:popup?/:id?", {
			id,
			popup,
		});
		if (typeof modifiedTo === "string") {
			modifiedTo = modifiedTo.slice(-1) === "/" ? modifiedTo.slice(0, -1) : modifiedTo;
			modifiedTo = `${modifiedTo}${pathSuffix}`;
		} else {
			const newPath =
				modifiedTo.pathname && modifiedTo.pathname.slice(-1) === "/"
					? modifiedTo.pathname.slice(0, -1)
					: modifiedTo.pathname;
			modifiedTo = { ...modifiedTo, pathname: `${newPath || ""}${pathSuffix}` };
		}
	}
	return <Link to={modifiedTo} {...linkProps} />;
};
