export const copyToClipboard = (url: string) => {
	const listener = (e: ClipboardEvent) => {
		e.clipboardData?.setData("text/plain", url);
		e.preventDefault();
	};

	document.addEventListener<"copy">("copy", listener);
	document.execCommand("copy");
	document.removeEventListener("copy", listener);
};
