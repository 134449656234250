import React, { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import {
	Checkbox,
	FilledInput,
	FormControl,
	Grid,
	InputLabel,
	ListItemText,
	MenuItem,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
} from "@material-ui/core";
import locale from "../../../../helpers/strings";
import useAppContext from "../../../../contexts/AppContext";
import {
	ELoginRole,
	GetLoginsDocument,
	LoginForListFragment,
	useGetLoginsLazyQuery,
	useChangeUserRoleMutation,
	GetLoginsQuery,
	RegionFullFragment,
	useSetHomeRegionMutation,
} from "@rsonav/protocol";
import { formatPhone, TLocale } from "@rsonav/shared-config";
import { FullScreenPlaceholder, StandartTextInput } from "../..";
import _ from "lodash";

export interface IUsersListTabProps {
	onLoading: (loading: boolean) => void;
}

interface ISelectorProps {
	user?: LoginForListFragment;
	onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
}

interface IRoleSelectorProps extends ISelectorProps {
	selectedRoles?: ELoginRole[];
	multiple?: boolean;
}

interface Column {
	field: keyof LoginForListFragment;
	label: keyof TLocale;
	className?: string;
	align?: "right";
	format?: (value: any, props?: ISelectorProps) => ReactNode;
}

const USERS_PER_PAGE = 10;

const rolesList: { [key in ELoginRole]: keyof TLocale } = {
	User: "user",
	Admin: "admin",
	Moderator: "moderator",
};

const columns: Column[] = [
	{ field: "Id", label: "id", className: "user-id" },
	{ field: "UserName", label: "userName", className: "user-name" },
	{
		field: "Phone",
		label: "phoneNumber",
		className: "phone",
		format: (phone) => <a href={`tel://${phone}`}>{formatPhone(phone)}</a>,
	},
	{
		field: "Email",
		label: "email",
		className: "e-mail",
		format: (email) => <a href={`mailto://${email}`}>{email}</a>,
	},
	{ field: "LastPlatform", label: "platform", className: "platform" },
	{ field: "EcoPoints", label: "profile_ecoPoints", className: "eco-poitns" },
	{
		field: "Role",
		label: "role",
		className: "role",
		format: (role: ELoginRole, props) => (props ? <RoleSelector {...props} /> : locale[rolesList[role]]),
	},
	{
		field: "HomeRegion",
		label: "region",
		className: "region",
		format: (region: RegionFullFragment | null | undefined, props) =>
			props ? <RegionSelector {...props} /> : region?.Name,
	},
];

const RegionSelector = (props: ISelectorProps) => {
	const { regions } = useAppContext();

	return (
		<FormControl className="status-filter" margin="dense" fullWidth variant="filled">
			<InputLabel color="primary">{locale.region}</InputLabel>
			<Select
				MenuProps={{ className: "status-filter", variant: "menu" }}
				value={props.user?.HomeRegion?.Id || ""}
				variant="filled"
				onChange={props.onChange}
				input={<FilledInput />}
				renderValue={(selected) => regions?.find((r) => r.Id === (selected as string))?.Name || locale.notSet}
			>
				{regions?.map((region) => (
					<MenuItem key={region.Id} value={region.Id}>
						<ListItemText primary={region.Name} />
						<Checkbox checked={props.user?.HomeRegion?.Id === region.Id} color="primary" />
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

const RoleSelector = (props: IRoleSelectorProps) => {
	return (
		<FormControl className="status-filter" margin="dense" fullWidth variant="filled">
			<InputLabel color="primary">{locale.role}</InputLabel>
			<Select
				MenuProps={{ className: "status-filter", variant: "menu" }}
				multiple={props.multiple}
				value={props.user?.Role ? [props.user?.Role] : props.selectedRoles}
				variant="filled"
				onChange={props.onChange}
				input={<FilledInput />}
				renderValue={(selected) =>
					selected
						? (selected as ELoginRole[]).map((selected) => locale[rolesList[selected]]).join(", ")
						: locale.notSet
				}
			>
				{Object.entries(rolesList).map(([role, localeKey]) => (
					<MenuItem key={role} value={role}>
						<ListItemText primary={locale[localeKey]} />
						<Checkbox
							checked={
								!!(
									props.user?.Role === (role as ELoginRole) ||
									props.selectedRoles?.includes(role as ELoginRole)
								)
							}
							color="primary"
						/>
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export const UsersListTab = (props: IUsersListTabProps) => {
	const { onLoading } = props;

	const { login } = useAppContext();

	const [getLogins, { data, loading, fetchMore }] = useGetLoginsLazyQuery({
		variables: { input: { Pagination: { Limit: USERS_PER_PAGE * 2, Offset: 0 } } },
		fetchPolicy: "cache-and-network",
	});

	const [changeRole, { loading: changingRole }] = useChangeUserRoleMutation();
	const [setHomeRegion, { loading: settingRegion }] = useSetHomeRegionMutation();

	const [page, setPage] = useState(0);
	const [searchString, setSearchString] = useState<string>("");
	const [roles, setRoles] = useState<ELoginRole[]>([]);

	const needFetch = useRef(true);

	useEffect(() => {
		onLoading(loading || changingRole || settingRegion);
	}, [loading, changingRole, settingRegion, onLoading]);

	useEffect(() => {
		setPage(0);
		needFetch.current = true;
	}, []);

	const debouncedFetch = useMemo(
		() =>
			_.debounce((search: string, roles: ELoginRole[]) => {
				getLogins({
					variables: {
						input: { Search: search, Role: roles, Pagination: { Limit: USERS_PER_PAGE * 2, Offset: 0 } },
					},
				});
				setPage(0);
				needFetch.current = true;
			}, 500),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[getLogins],
	);

	useEffect(() => {
		if (!data) {
			getLogins({
				variables: {
					input: {
						Search: searchString,
						Role: roles,
						Pagination: { Limit: USERS_PER_PAGE * 2, Offset: 0 },
					},
				},
			});
			return;
		}
		debouncedFetch(searchString, roles);
		// }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getLogins, debouncedFetch, roles, searchString]);

	if (!login) {
		return null;
	}

	const logins = data?.getLogins;

	const handleChangePage = (_event: unknown, newPage: number) => {
		setPage(newPage);
		if (!fetchMore) {
			return;
		}
		if (needFetch.current && USERS_PER_PAGE * (newPage + 2) > (logins?.length || 0)) {
			fetchMore({
				query: GetLoginsDocument,
				variables: {
					input: {
						Search: searchString,
						Role: roles,
						Pagination: {
							Offset: logins?.length || 0,
							Limit: USERS_PER_PAGE,
						},
					},
				},
				updateQuery: (prevResult, { fetchMoreResult }) => {
					if (!fetchMoreResult || !fetchMoreResult.getLogins || fetchMoreResult.getLogins.length === 0) {
						needFetch.current = false;
						return prevResult;
					}
					if (fetchMoreResult.getLogins.length < USERS_PER_PAGE) {
						needFetch.current = false;
					}
					const fetchListSearch = [...(prevResult.getLogins || []), ...fetchMoreResult.getLogins];
					return { getLogins: fetchListSearch };
				},
			});
		}
	};

	const changeSearchString = (value: string) => {
		setSearchString(value);
	};

	const handleChangeRoles = (event: React.ChangeEvent<{ value: unknown }>) => {
		setRoles(event.target.value as ELoginRole[]);
	};

	const handleChangeUser = async (
		event: React.ChangeEvent<{ value: unknown }>,
		userId: string,
		field: keyof LoginForListFragment,
	) => {
		switch (field) {
			case "Role":
				return handleChangeUserRole(event.target.value as ELoginRole, userId);
			case "HomeRegion":
				return handleChangeUserRegion(event.target.value as string, userId);
		}
	};

	const handleChangeUserRole = async (value: ELoginRole, userId: string) => {
		await changeRole({
			variables: { id: userId, role: value },
			update: (cache, res) => {
				if (!res.data?.changeUserRole.data?.Login) {
					return;
				}
				const queryParams = {
					query: GetLoginsDocument,
					variables: { input: { Search: searchString, Role: roles, Pagination: { Limit: 20, Offset: 0 } } },
				};

				const curData = cache.readQuery<GetLoginsQuery>(queryParams);
				if (!curData) {
					return;
				}

				const idx = curData.getLogins.findIndex(
					(login) => login.Id === res.data?.changeUserRole.data?.Login.Id,
				);
				if (idx !== undefined && idx >= 0) {
					const newData = _.clone(curData.getLogins);
					newData[idx] = res.data.changeUserRole.data.Login;
					cache.writeQuery<GetLoginsQuery>({ ...queryParams, data: { getLogins: newData } });
				}
			},
		});
	};

	const handleChangeUserRegion = async (value: string, userId: string) => {
		await setHomeRegion({
			variables: { id: userId, regionId: value },
			update: (cache, res) => {
				if (!res.data?.setHomeRegion.data?.Login) {
					return;
				}
				const queryParams = {
					query: GetLoginsDocument,
					variables: { input: { Search: searchString, Role: roles, Pagination: { Limit: 20, Offset: 0 } } },
				};

				const curData = cache.readQuery<GetLoginsQuery>(queryParams);
				if (!curData) {
					return;
				}

				const idx = curData.getLogins.findIndex((login) => login.Id === res.data?.setHomeRegion.data?.Login.Id);
				if (idx !== undefined && idx >= 0) {
					const newData = _.clone(curData.getLogins);
					newData[idx] = res.data.setHomeRegion.data.Login;
					cache.writeQuery<GetLoginsQuery>({ ...queryParams, data: { getLogins: newData } });
				}
			},
		});
	};

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12} md={8}>
					<StandartTextInput
						value={searchString}
						onChange={changeSearchString}
						label={locale.search}
						margin="dense"
						className="search-input"
						fullWidth
					/>
				</Grid>
				<Grid item xs={12} md={4}>
					<RoleSelector selectedRoles={roles} onChange={handleChangeRoles} multiple />
				</Grid>
			</Grid>
			{logins?.length ? (
				<>
					<TableContainer className="user-list-container">
						<Table stickyHeader size={login.Role === ELoginRole.Admin ? "small" : "medium"}>
							<TableHead>
								<TableRow>
									{columns.map((column) => (
										<TableCell key={column.field} className={column.className}>
											{locale[column.label]}
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{logins.slice(page * USERS_PER_PAGE, (page + 1) * USERS_PER_PAGE).map((row) => {
									return (
										<TableRow hover role="checkbox" tabIndex={-1} key={row.Id}>
											{columns.map((column) => {
												const value = row[column.field];
												return (
													<TableCell key={column.field} className={column.className}>
														{column.format
															? column.format(
																	value,
																	login.Role === ELoginRole.Admin
																		? {
																				user: row,
																				onChange: (event) =>
																					handleChangeUser(
																						event,
																						row.Id,
																						column.field,
																					),
																		  }
																		: undefined,
															  )
															: value}
													</TableCell>
												);
											})}
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[USERS_PER_PAGE]}
						component="div"
						count={logins.length || 0}
						rowsPerPage={USERS_PER_PAGE}
						page={page}
						onChangePage={handleChangePage}
						labelDisplayedRows={({ from, to, count }) =>
							`${from}-${to} ${
								count !== -1
									? ` ${locale.fromPage} ${
											to - from === USERS_PER_PAGE && needFetch.current
												? `${locale.morePage} ${count}`
												: count
									  }`
									: ""
							}`
						}
						nextIconButtonText={locale.nextPage}
						backIconButtonText={locale.prevPage}
					/>
				</>
			) : (
				<FullScreenPlaceholder titleKey={loading ? "loading" : "nothingFound"} />
			)}
		</>
	);
};
