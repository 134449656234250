import React, { useCallback, useEffect, useRef, useState } from "react";
import {
	Clusterer,
	GeolocationControl,
	Map as YandexMap,
	Placemark,
	SearchControl,
	YMapsApi,
	ZoomControl,
} from "react-yandex-maps";
import {
	EGeoObjectStatus,
	EGeoObjectType,
	GeoObjectFilters,
	GeoObjectForMapFragment,
	useGetManyGeoObjectsForMapLazyQuery,
} from "@rsonav/protocol";
import useAppContext from "../contexts/AppContext";
import _ from "lodash";
import locale from "../helpers/strings";
import { useHistory, useLocation } from "react-router-dom";
import { Button, IconButton, useMediaQuery } from "@material-ui/core";
import { FiltersDialog } from "./common/FiltersDialog";
import { mobileThreshold } from "../helpers/constants";
import AddLocationIcon from "@material-ui/icons/AddLocation";
import { IGeoObjectLocation } from ".";

const DEFAULT_GEO: [number, number] = [57.0050671, 40.9766453];
const DEFAULT_ZOOM = 16;
const MAX_OBJECTS_ZOOM = 12;
const OBJECTS_PER_PAGE = 100;
const COORD_PRECISION = 1000000000;

const defaultFilters: GeoObjectFilters = {
	Pagination: { Limit: OBJECTS_PER_PAGE, Offset: 0 },
	Status: [EGeoObjectStatus.Approved],
	RecycledType: [],
	Type: [],
	City: [],
};

const iconColors = {
	[EGeoObjectType.Containers]: "#51CACB",
	[EGeoObjectType.Stationary]: "#75C4FD",
	[EGeoObjectType.Temporary]: "#FFB067",
	[EGeoObjectStatus.New]: "#86939E",
	[EGeoObjectStatus.Rejected]: "#D0452B",
};

export const ObjectsMap = () => {
	const { geoPosition, forceRefetchTime, login, editCoords, setEditCoords } = useAppContext();
	const history = useHistory();
	const mobileScreen = useMediaQuery(mobileThreshold);
	const curObject = useLocation<IGeoObjectLocation>().state?.object;

	const map = useRef<any>();
	const ymaps = useRef<YMapsApi>();
	const needFetch = useRef<boolean>(true);
	const currentVariables = useRef<GeoObjectFilters>(defaultFilters);

	const [geo, setGeo] = useState<[number, number]>(DEFAULT_GEO);
	const [filters, setFilters] = useState<GeoObjectFilters>(defaultFilters);
	const [filtersOpened, setFiltersOpened] = useState<boolean>(false);
	const [selectedObject, setSelectedObject] = useState<GeoObjectForMapFragment>();
	const [searchResults, setSearchResults] = useState(new Map<string, GeoObjectForMapFragment>());
	const [zoom, setZoom] = useState<number>(0);

	const [getGeoObjects, { data }] = useGetManyGeoObjectsForMapLazyQuery({
		fetchPolicy: "no-cache",
	});

	const [getClosestObject, { data: closestObject }] = useGetManyGeoObjectsForMapLazyQuery({
		fetchPolicy: "no-cache",
	});

	const setCenter = useCallback((coords: [number, number], zoom?: number) => {
		if (map.current) {
			map.current.setCenter(coords, zoom, {
				duration: 1000,
				timingFunction: "ease-in-out",
			});
		}
	}, []);

	const updateSearchResults = useCallback(
		(oldResults: Map<string, GeoObjectForMapFragment>, data: GeoObjectForMapFragment[]) => {
			for (const object of data) {
				oldResults.set(object.Id, object);
				if (selectedObject?.Id === object.Id) {
					setSelectedObject(object);
				}
			}
			return new Map(oldResults);
		},
		[selectedObject?.Id],
	);

	const resetSearchResults = useCallback(() => {
		setSearchResults(new Map<string, GeoObjectForMapFragment>());
		currentVariables.current = {
			...currentVariables.current,
			...filters,
			Pagination: { Limit: OBJECTS_PER_PAGE, Offset: 0 },
		};
		needFetch.current = true;
		getGeoObjects({ variables: { input: currentVariables.current } });
	}, [filters, getGeoObjects]);

	useEffect(() => {
		if (geoPosition) {
			setGeo((prevState) => {
				if (geoPosition.coords.latitude !== prevState[0] || geoPosition.coords.longitude !== prevState[1]) {
					setCenter([geoPosition.coords.latitude, geoPosition.coords.longitude]);
					return [geoPosition.coords.latitude, geoPosition.coords.longitude];
				} else {
					return prevState;
				}
			});
		}
	}, [geoPosition, setCenter]);

	useEffect(() => {
		if (data?.getManyGeoObjects?.length === OBJECTS_PER_PAGE) {
			needFetch.current = true;
		}
		setSearchResults((prevState) => {
			if (data?.getManyGeoObjects?.length) {
				return updateSearchResults(prevState, data.getManyGeoObjects);
			} else {
				return prevState;
			}
		});
	}, [data, updateSearchResults]);

	useEffect(() => {
		if (!map.current) {
			return;
		}
		if (zoom < MAX_OBJECTS_ZOOM) {
			return;
		}

		resetSearchResults();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [forceRefetchTime]);

	useEffect(() => {
		if (!map.current) {
			return;
		}
		const curCoords: [number, number] = map.current.getCenter();
		getClosestObject({
			variables: {
				input: { ...filters, Lat: curCoords[0], Long: curCoords[1], Pagination: { Offset: 0, Limit: 1 } },
			},
		});
	}, [filters, getClosestObject]);

	useEffect(() => {
		if (!closestObject?.getManyGeoObjects?.length) {
			return;
		}
		const foundObject = closestObject.getManyGeoObjects[0];
		const curCoords: [number, number] = map.current.getCenter();

		if (
			Math.round(curCoords[0] * COORD_PRECISION) !== Math.round(foundObject.Lat * COORD_PRECISION) ||
			Math.round(curCoords[1] * COORD_PRECISION) !== Math.round(foundObject.Long * COORD_PRECISION)
		) {
			setCenter([foundObject.Lat, foundObject.Long], DEFAULT_ZOOM);
			setSearchResults(new Map<string, GeoObjectForMapFragment>());
		} else {
			if (zoom >= MAX_OBJECTS_ZOOM) {
				resetSearchResults();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [closestObject, getGeoObjects]);

	useEffect(() => {
		if (!needFetch.current) {
			return;
		}

		needFetch.current = false;
		if (searchResults.size < OBJECTS_PER_PAGE) {
			return;
		}

		currentVariables.current = {
			...currentVariables.current,
			Pagination: {
				...currentVariables.current.Pagination,
				Offset:
					(currentVariables.current.Pagination.Offset || 0) +
					(currentVariables.current.Pagination.Limit || OBJECTS_PER_PAGE),
			},
		};

		getGeoObjects({
			variables: {
				input: currentVariables.current,
			},
		});
	}, [getGeoObjects, searchResults]);

	useEffect(() => {
		if (curObject?.Lat && curObject.Long) {
			setCenter([curObject.Lat, curObject.Long]);
		}
	}, [curObject, setCenter]);

	useEffect(() => {
		if (!editCoords) return;
		const [lat, long] = editCoords;
		if (lat === -1000 && long === -1000) {
			const curCoords: [number, number] = map.current.getCenter();
			setEditCoords([...curCoords]);
		}
	}, [editCoords, setEditCoords]);

	const showObjectInfo = (object: GeoObjectForMapFragment) => {
		if (object.Id !== curObject?.Id) {
			history.push(`/geo/${object.Id}`, { object });
		}
	};

	const updateObjects = (event: any) => {
		refreshObjects(event.originalEvent.newBounds[0], event.originalEvent.newBounds[1], event.originalEvent.newZoom);
	};

	const setCoords = (event: any) => {
		if (editCoords) {
			setEditCoords(event.get("coords"));
		}
	};

	const goToEditCoords = () => {
		if (!editCoords) {
			const curCoords: [number, number] = map.current.getCenter();
			history.push("/geo/add");
			setEditCoords([...curCoords]);
		} else {
			setCenter([...editCoords]);
		}
	};

	const refreshObjects = _.debounce((top: number[], bottom: number[], zoom: number) => {
		setZoom(zoom);
		if (zoom < MAX_OBJECTS_ZOOM) {
			return;
		}
		currentVariables.current = {
			...filters,
			TopLat: top[0],
			TopLong: top[1],
			BottomLat: bottom[0],
			BottomLong: bottom[1],
		};
		needFetch.current = true;
		getGeoObjects({ variables: { input: currentVariables.current } });
	}, 500);

	const closeFilters = () => {
		setFiltersOpened(false);
	};

	return (
		<div className="map-container">
			<YandexMap
				defaultState={{
					center: DEFAULT_GEO,
					zoom: DEFAULT_ZOOM,
				}}
				width="100%"
				height="100%"
				className="ya-map"
				onBoundschange={updateObjects}
				onClick={setCoords}
				onLoad={(ympasInstance) => {
					ymaps.current = ympasInstance;
				}}
				instanceRef={(instance) => {
					if (!map.current) {
						map.current = instance;
						const bounds = map.current?.getBounds();
						refreshObjects(bounds[0], bounds[1], map.current?.getZoom());
						if (curObject?.Lat && curObject.Long) {
							setCenter([curObject.Lat, curObject.Long]);
						}
					}
				}}
			>
				<Clusterer
					options={{
						preset: "islands#grayClusterIcons",
						gridSize: 128,
						hasBalloon: false,
						hasHint: false,
						clusterIconColor: iconColors[EGeoObjectType.Containers],
						maxZoom: 17,
					}}
				>
					{zoom >= MAX_OBJECTS_ZOOM &&
						!editCoords &&
						Array.from(searchResults.values()).map((object) => (
							<Placemark
								key={object.Id}
								geometry={[object.Lat, object.Long]}
								onClick={() => showObjectInfo(object)}
								options={{
									hasBalloon: false,
									hasHint: false,
									iconColor:
										iconColors[
											object.Status === EGeoObjectStatus.Approved ? object.Type : object.Status
										],
									preset: "islands#blueCircleDotIcon",
								}}
							/>
						))}
				</Clusterer>
				{editCoords && (
					<Placemark
						geometry={editCoords}
						options={{
							hasBalloon: false,
							hasHint: false,
							iconColor: iconColors[EGeoObjectType.Containers],
							preset: "islands#blueCircleDotIcon",
						}}
					/>
				)}
				{geo && (
					<Placemark
						geometry={geo}
						options={{
							hasBalloon: false,
							hasHint: false,
							preset: "islands#geolocationIcon",
						}}
					/>
				)}
				<SearchControl
					className="search-control"
					options={{ float: "right", noPlacemark: true, placeholderContent: locale.searchPlaceholder }}
				/>
				<ZoomControl
					options={{ size: "small", position: { left: "auto", top: "auto", right: 24, bottom: 96 } }}
				/>
				<GeolocationControl options={{ position: { left: "auto", top: "auto", right: 24, bottom: 32 } }} />
			</YandexMap>
			{mobileScreen ? (
				<IconButton className="button-filter" onClick={() => setFiltersOpened(true)}>
					<i className="icon icon-filter" />
				</IconButton>
			) : (
				<Button
					className="button-filter"
					startIcon={<i className="icon icon-filter" />}
					variant="contained"
					onClick={() => setFiltersOpened(true)}
				>
					{locale.search_filters}
				</Button>
			)}
			{!!login && (
				<IconButton className="button-add-geo" onClick={goToEditCoords} disabled={!!curObject}>
					<AddLocationIcon />
				</IconButton>
			)}
			<FiltersDialog
				open={filtersOpened}
				onClose={closeFilters}
				defaultFilters={defaultFilters}
				filters={filters}
				setFilters={setFilters}
			/>
		</div>
	);
};
