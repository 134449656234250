import { ApolloClient, InMemoryCache, ApolloLink, concat, GraphQLRequest } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import OnError from "./OnError";
import { IConfig } from "@rsonav/shared-config/dist";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import { customFetch } from "./uploadFetch";

export interface ICreateApolloClientParams {
	sharedConfig: IConfig;
	onErrorLogout: () => Promise<void>;
	getAuthToken: () => string | null;
	setErrorCode: (value: string | null) => void;
}

const createApolloClient = (params: ICreateApolloClientParams) => {
	const endpointsConfig = params.sharedConfig.endpoints;
	const graphqlEndpoint = `${endpointsConfig.graphqlUseSSL ? "https" : "http"}://${endpointsConfig.graphqlHost}${
		endpointsConfig.graphqlEndpoint
	}`;

	const errorLink = onError(OnError({ logout: params.onErrorLogout, setErrorCode: params.setErrorCode }));

	const checkResponseError = new ApolloLink((operation, forward) => {
		return forward(operation).map((response) => {
			if (response.data) {
				for (const result of Object.values(response.data)) {
					if (result.errorCode) {
						params.setErrorCode(result.errorCode);
						break;
					}
				}
			}
			return response;
		});
	});

	/**
	 * Создается канал для хттп. Применяются с конца в начало, т.ч. RetryLink будет в конце и будет ретраить до конца
	 */
	const httpLink = concat(
		checkResponseError,
		concat(
			new RetryLink({ attempts: { max: Infinity } }),
			concat(
				errorLink,
				(createUploadLink({
					uri: graphqlEndpoint,
					fetch: customFetch,
				}) as unknown) as ApolloLink, // Types workaround: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/47369
			),
		),
	);

	/**
	 * Мидлваря для добавления токена авторизации к каждому запросу, если токен есть
	 */
	const { getAuthToken } = params;
	const setAuthHeader = (_req: GraphQLRequest, { headers }: any) => {
		const token = getAuthToken();
		const authorizationHeader = token ? `Bearer ${token}` : undefined;

		if (authorizationHeader !== undefined) {
			return {
				headers: {
					...headers,
					authorization: authorizationHeader,
				},
			};
		}

		return;
	};
	const middlewareAuthLink = setContext(setAuthHeader);

	/**
	 * Мидлваря добавляется к созданному линку
	 */
	const httpLinkWithAuthToken = middlewareAuthLink.concat(httpLink);

	/**
	 * Разделение рассыки сабскрипшенов и не-сабскрипшенов между каналами
	 * Сабскрипшены идут в ws, остальное в http
	 */
	// const link = split(
	// 	({ query }) => {
	// 		const { kind, operation } = getMainDefinition(query) as any;
	// 		return kind === "OperationDefinition" && operation === "subscription";
	// 	},
	// 	wsLink,
	// 	httpLinkWithAuthToken
	// );
	const link = httpLinkWithAuthToken;

	const links = [link];

	const client = new ApolloClient({
		link: ApolloLink.from(links),
		cache: new InMemoryCache(),
		assumeImmutableResults: true,
	});

	return client;
};

export default createApolloClient;
