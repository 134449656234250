import React, { useEffect, useState } from "react";
import { Box, Grid, IconButton, Typography } from "@material-ui/core";
import { FullScreenPlaceholder, IStandartViewProps, ISwitchBarItem, StandartView, SwitchBar } from "../../components";
import locale from "../../helpers/strings";
import useAppContext from "../../contexts/AppContext";
import {
	ELoginRole,
	ArticleFullFragment,
	useGetManyArticlesLazyQuery,
	ArticleForListFragment,
	EArticleType,
} from "@rsonav/protocol";
import { UpsertArticleDialog } from "./UpsertArticleDialog";
import { NewsListItem } from "./NewsListItem";
import { ViewArticleDialog } from "./ViewArticleDialog";
const NEWS_PER_PAGE = 50;

const articleTypesList: ISwitchBarItem<EArticleType[]>[] = [
	{ labelKey: "news_newsTabTitle", value: [EArticleType.News] },
	{ labelKey: "news_helpTabTitle", value: [EArticleType.Help, EArticleType.Law] },
];

export const News = (props: Pick<IStandartViewProps, "policyOpened">) => {
	const { login, curRegion, localRegion } = useAppContext();
	const [getArticles, { data, loading }] = useGetManyArticlesLazyQuery({
		variables: { input: { Pagination: { Limit: NEWS_PER_PAGE, Offset: 0 } } },
		fetchPolicy: "cache-and-network",
	});

	const [articleTypes, setArticleTypes] = useState<EArticleType[]>(articleTypesList[0].value);

	const [article, setArticle] = useState<Partial<ArticleFullFragment>>();
	const [viewArticle, setViewArticle] = useState<ArticleForListFragment>();

	const RegionId: string[] = [];
	if (login?.HomeRegion) {
		RegionId.push(login.HomeRegion.Id);
	} else if (localRegion) {
		RegionId.push(localRegion.Id);
	}

	if (curRegion && !RegionId.includes(curRegion.Id)) {
		RegionId.push(curRegion.Id);
	}

	useEffect(() => {
		getArticles({
			variables: { input: { Type: articleTypes, RegionId, Pagination: { Limit: NEWS_PER_PAGE, Offset: 0 } } },
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getArticles, articleTypes, login, localRegion, curRegion]);

	const openUpsert = (article: Partial<ArticleFullFragment>) => {
		setArticle(article);
	};

	const closeUpsert = () => {
		setArticle(undefined);
	};

	const openArticle = (article: ArticleForListFragment) => {
		setViewArticle(article);
	};

	const closeArticle = () => {
		setViewArticle(undefined);
	};

	return (
		<StandartView
			{...props}
			header={
				<Box className="news-header">
					<Grid container justify="space-between" alignItems="center" direction="row">
						<Grid item>
							<Typography variant="h4">{locale.news_title}</Typography>
						</Grid>
						{(login?.Role === ELoginRole.Admin || login?.Role === ELoginRole.Moderator) && (
							<Grid item>
								<IconButton onClick={() => openUpsert({})} color="primary" className="btn-add">
									<i className="icon icon-plus" />
								</IconButton>
							</Grid>
						)}
					</Grid>
					<SwitchBar
						fullWidth
						items={articleTypesList}
						onItemsChange={(items) => setArticleTypes(items[0])}
						selectedItems={[articleTypes]}
						color="primary"
					/>
				</Box>
			}
		>
			<Box className="news">
				<Grid container justify="flex-start" className="list-container">
					{loading ? (
						new Array(10).fill(false).map((_, i) => <NewsListItem key={i} />)
					) : data?.getManyArticles?.length ? (
						data.getManyArticles.map((article) => (
							<NewsListItem article={article} key={article.Id} onClick={openArticle} />
						))
					) : (
						<FullScreenPlaceholder titleKey="news_placeholderTitle" className="news" />
					)}
				</Grid>
				<UpsertArticleDialog article={article} onClose={closeUpsert} open={!!article} />
				<ViewArticleDialog
					article={viewArticle}
					onClose={closeArticle}
					open={!!viewArticle}
					onEdit={openUpsert}
				/>
			</Box>
		</StandartView>
	);
};
