import { createMuiTheme } from "@material-ui/core";

export const theme = createMuiTheme({
	palette: {
		primary: {
			light: "#81c784",
			main: "#4caf50",
			dark: "#388e3c",
		},
		secondary: {
			light: "#c147d7",
			main: "#9c27b0",
			dark: "#761d86",
		},
	},
	props: {
		MuiFilledInput: {
			disableUnderline: true,
		},
		MuiButton: {
			disableElevation: true,
		},
	},
});
