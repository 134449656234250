import { Slide, IconButton, Box, Paper, Portal } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { GeoObjectFullFragment, useGetOneGeoObjectLazyQuery } from "@rsonav/protocol";
import { Address } from "./Address";
import { Containers } from "./Containers";
import { GeoObjectBadge } from "./GeoObjectBadge";
import { OrgInfo } from "./OrgInfo";
import { RecycledTypes } from "./RecycledTypes";
import { Description } from "./Description";
import { Gallery } from "./Gallery";
import { GeoActions } from "./GeoActions";
import { FavoriteButton } from "./FavoriteButton";
import useAppContext from "../../../contexts/AppContext";
import { UpsertGeoForm } from "./UpsertGeoForm";
import { SupportButton } from "./SupportButton";
import { Dates } from "./Dates";
import { WorkingHours } from "./WorkingHours";

export interface IViewGeoProps {
	open: boolean;
	onClose: () => void;
}

export interface IGeoObjectLocation {
	object?: Partial<GeoObjectFullFragment> & Pick<GeoObjectFullFragment, "Id">;
}

export const ViewGeo = (props: IViewGeoProps) => {
	const { open, onClose } = props;
	const { login, editCoords } = useAppContext();

	const history = useHistory();
	const object = useLocation<IGeoObjectLocation>().state?.object;
	const { id } = useParams<{ id: string }>();

	const [geoObject, setGeoObject] = useState(object);
	const [getObject, { data, loading }] = useGetOneGeoObjectLazyQuery({ fetchPolicy: "cache-and-network" });

	useEffect(() => {
		if (!loading && data?.getOneGeoObject) {
			if (object) {
				setGeoObject(data?.getOneGeoObject);
			} else {
				history.replace({ ...history.location, state: { object: data.getOneGeoObject } });
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, history, loading]);

	useEffect(() => {
		if (object) {
			setGeoObject(object);
		}
	}, [object]);

	useEffect(() => {
		if (editCoords) {
			setGeoObject((prevState) => ({
				...prevState,
				Id: prevState?.Id || "",
				Lat: editCoords[0],
				Long: editCoords[1],
			}));
		}
	}, [editCoords]);

	useEffect(() => {
		if (id) {
			getObject({ variables: { id } });
		}
	}, [getObject, id]);

	if (!geoObject) {
		return null;
	}

	const refetchObject = () => {
		getObject({ variables: { id } });
	};

	const closeWithClear = () => {
		setGeoObject(undefined);
		onClose();
	};

	return (
		<Portal>
			<Slide in={open} direction="right">
				<div className={`MuiDialog-root geo-object-card ${open ? "opened" : "closed"}`}>
					<Paper>
						<Box className="toolbar">
							<div>
								<IconButton onClick={closeWithClear} className="icon icon-arrow-left" />
								{(!login || !editCoords) && <GeoObjectBadge geoObject={geoObject} />}
							</div>
							{(!login || !editCoords) && (
								<GeoActions geoObject={geoObject} refetchObject={refetchObject} />
							)}
						</Box>
						<Box className="content">
							{login && editCoords ? (
								<UpsertGeoForm geoObject={geoObject} onClose={closeWithClear} />
							) : (
								<>
									<Address geoObject={geoObject} />
									<FavoriteButton geoObject={geoObject} refetchObject={refetchObject} />
									<Gallery geoObject={geoObject} />
									<Containers geoObject={geoObject} />
									<Dates geoObject={geoObject} />
									<WorkingHours geoObject={geoObject} />
									<RecycledTypes geoObject={geoObject} />
									<OrgInfo geoObject={geoObject} />
									<Description geoObject={geoObject} />
									<SupportButton geoObject={geoObject} />
								</>
							)}
						</Box>
					</Paper>
				</div>
			</Slide>
		</Portal>
	);
};
