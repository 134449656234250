import { TLocale } from "@rsonav/shared-config";
import { EGeoObjectType } from "@rsonav/protocol";

export interface IBadgeProps {
	labelKey: keyof TLocale;
	variant: "primary" | "secondary" | "blue";
}

export const badgeConfigs: { [key: string]: IBadgeProps } = {
	[EGeoObjectType.Containers]: { variant: "primary", labelKey: "geo_objectTypeContainers" },
	[EGeoObjectType.Temporary]: { variant: "secondary", labelKey: "geo_objectTypeTemporary" },
	[EGeoObjectType.Stationary]: { variant: "blue", labelKey: "geo_objectTypeStationary" },
};
