import React, { useCallback, useEffect, useRef, useState } from "react";
import {
	AttachmentFullFragment,
	EAttachmentType,
	ERequestType,
	GeoObjectFullFragment,
	UpsertSupportRequestInput,
	useUpsertSupportRequestMutation,
} from "@rsonav/protocol";
import {
	Box,
	Button,
	Dialog,
	DialogContent,
	IconButton,
	Link,
	Paper,
	Portal,
	Slide,
	Typography,
} from "@material-ui/core";
import locale from "../../helpers/strings";
import useAppContext from "../../contexts/AppContext";
import { requestTypes } from "./constants";
import { Controller, useForm } from "react-hook-form";
import {
	FullScreenPlaceholder,
	Gallery,
	IGeoObjectLocation,
	StandartCheckbox,
	StandartTextInputController,
} from "../../components";
import { regExps } from "@rsonav/shared-config";
import { useLocation } from "react-router-dom";
import { withYMaps, WithYMapsProps } from "react-yandex-maps";
import _ from "lodash";

export interface IUpsertRequestDialogProps {
	open: boolean;
	onClose: () => void;
	onOpenPolicy: () => void;
	requestType?: ERequestType;
}

export const UpsertRequestDialog = withYMaps<IUpsertRequestDialogProps>((props) => {
	const { requestType, open, onClose, onOpenPolicy, ymaps } = props as IUpsertRequestDialogProps & WithYMapsProps;

	const geoObject = useLocation<IGeoObjectLocation>().state?.object;
	const { login, editCoords, setEditCoords } = useAppContext();

	const [upsertSupportRequest, { loading }] = useUpsertSupportRequestMutation();

	const [formSent, setFormSent] = useState(false);

	const attachments = useRef<AttachmentFullFragment[]>([]);
	const onUpdateAttachments = useCallback(
		(newAttachments: AttachmentFullFragment[]) => (attachments.current = newAttachments),
		[],
	);

	let defaultValues: Partial<UpsertSupportRequestInput> = {
		Type: requestType,
		Email: login?.Email || undefined,
		UserName: login?.UserName || undefined,
		PolicyAccepted: login?.PolicyAccepted || false,
		Message: "",
		GeoObjectId: geoObject?.Id || undefined,
		Address: getFullAddress(geoObject),
		Lat: geoObject?.Lat || undefined,
		Long: geoObject?.Long || undefined,
	};

	const form = useForm<UpsertSupportRequestInput>({
		mode: "onChange",
		reValidateMode: "onChange",
		defaultValues,
		shouldUnregister: false,
	});
	const { handleSubmit, errors, control } = form;

	const submit = (data: UpsertSupportRequestInput) => {
		data.AttachmentId = attachments.current.map((a) => a.Id);
		data.Email = data.Email.trim();
		data.Message = data.Message.trim();
		data.UserName = data.UserName.trim();

		upsertSupportRequest({ variables: { input: data }, refetchQueries: [] })
			.then((result) => {
				if (result.data?.upsertSupportRequest.success) {
					setFormSent(true);
				}
			})
			.catch((reason) => {
				console.warn("Error sending upsertSupportRequest form:", reason);
			});
	};

	useEffect(() => {
		if (!login) {
			return;
		}
		form.setValue("Email", login.Email);
		form.setValue("UserName", login.UserName);
		form.setValue("PolicyAccepted", login.PolicyAccepted);
		form.setValue("Type", requestType);
		form.setValue("GeoObjectId", geoObject?.Id);
		form.setValue("Address", getFullAddress(geoObject));
		form.setValue("Lat", geoObject?.Lat);
		form.setValue("Long", geoObject?.Long);
		if (requestType && !geoObject) {
			setEditCoords([-1000, -1000]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [login, requestType, geoObject]);

	useEffect(() => {
		if (!editCoords) return;
		const [lat, long] = editCoords;
		if (lat === -1000 && long === -1000) return;
		if (defaultValues.Address && form.getValues("Lat") === lat && form.getValues("Long") === long) return;

		form.setValue("Lat", lat);
		form.setValue("Long", long);
		ymaps
			.geocode(`${lat},${long}`, { json: true, results: 1, kind: "house" })
			.then((response: any) => {
				if (
					response?.GeoObjectCollection?.featureMember &&
					response.GeoObjectCollection.featureMember.length > 0
				) {
					const found = response.GeoObjectCollection.featureMember[0].GeoObject;
					const address = found.metaDataProperty.GeocoderMetaData.Address;
					const addressParts: Array<{ kind: string; name: String }> | undefined = address?.Components;
					const city = addressParts?.find((component) => component.kind === "locality");
					const region = _.findLast(addressParts, (component) => component.kind === "province");

					const addressStr: String[] = [];
					if (region) addressStr.push(region.name);
					if (city) addressStr.push(city.name);
					if (found) addressStr.push(found.name);
					if (addressStr.length) form.setValue("Address", addressStr.join(", "));
					void form.trigger("Address");
				}
			})
			.catch((ex: any) => {
				console.warn("Error getting address:", JSON.stringify(ex));
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editCoords]);

	if (!login || !requestType) {
		return null;
	}

	const closeDialog = () => {
		form.reset();
		setFormSent(false);
		setEditCoords(undefined);
		onClose();
	};

	if (formSent) {
		return (
			<Dialog onClose={closeDialog} className="upsert-request-dialog" open={open}>
				<div className="MuiDialogTitle-root">
					<div className="container">
						<Box className="toolbar">
							<IconButton onClick={closeDialog}>
								<i className="icon icon-arrow-left" />
							</IconButton>
						</Box>
					</div>
				</div>
				<DialogContent>
					<FullScreenPlaceholder
						titleKey="support_finishTitle"
						messageKey="support_finishMessage"
						className="done"
						actionKey="close"
						action={closeDialog}
					/>
				</DialogContent>
			</Dialog>
		);
	}

	return (
		<Portal>
			<Slide in={open} direction="right">
				<div className={`MuiDialog-root upsert-request-dialog ${open ? "opened" : "closed"}`}>
					<Paper className="MuiDialog-paper">
						<div className="MuiDialogTitle-root">
							<div className="container">
								<Box className="toolbar">
									<IconButton onClick={closeDialog}>
										<i className="icon icon-arrow-left" />
									</IconButton>
									<Typography variant="h4">{locale[requestTypes[requestType].localeKey]}</Typography>
								</Box>
							</div>
						</div>
						<DialogContent>
							<form onSubmit={handleSubmit(submit)}>
								<StandartTextInputController
									name="UserName"
									control={control}
									errorMessage={errors.UserName?.message}
									label={locale.userName}
									margin="normal"
									rules={{
										required: locale.required,
										maxLength: {
											value: 200,
											message: locale.maxLength,
										},
									}}
								/>
								<StandartTextInputController
									name="Email"
									control={control}
									errorMessage={errors.Email?.message}
									label={locale.email}
									margin="normal"
									rules={{
										required: locale.required,
										pattern: {
											value: regExps.email,
											message: locale.wrongEmail,
										},
									}}
								/>
								<StandartTextInputController
									name="Address"
									multiline
									disabled={!!geoObject}
									control={control}
									errorMessage={errors.Address?.message}
									label={locale.address}
									margin="normal"
									rules={{
										required:
											requestType !== ERequestType.Other
												? locale.upsertObject_addressRequired
												: undefined,
										maxLength: {
											value: 150,
											message: locale.maxLength,
										},
									}}
								/>
								<StandartTextInputController
									name="Message"
									control={control}
									errorMessage={errors.Message?.message}
									label={locale.support_text}
									margin="normal"
									multiline
									rows={5}
									rules={{
										required: locale.required,
										maxLength: {
											value: 200,
											message: locale.maxLength,
										},
									}}
								/>
								<Gallery
									attachmentType={EAttachmentType.SupportTicket}
									maxItems={10}
									acceptedFiles={["image/*"]}
									onUpdate={onUpdateAttachments}
									maxFileSize={2097152}
									addFileTip="add"
									compact
								/>
								<Controller
									name="PolicyAccepted"
									control={control}
									rules={{
										required: locale.support_policyError,
										validate: (value) => !!value === true || locale.support_policyError,
									}}
									render={({ onChange, value }) => (
										<Box className="MuiFormControl-marginNormal">
											<StandartCheckbox
												checked={value}
												onChange={(event) => onChange(event.target.checked)}
												name="policy-accepted"
												color="primary"
												label={
													<Typography variant="subtitle1">
														{locale.support_policyAccept}{" "}
														<Link onClick={onOpenPolicy}>{locale.support_policyLink}</Link>
													</Typography>
												}
											/>
										</Box>
									)}
								/>
								<Box className="MuiFormControl-marginNormal">
									<Button
										type="submit"
										variant="contained"
										color="primary"
										fullWidth
										disabled={!!errors.PolicyAccepted?.message || loading}
									>
										{locale.support_submit}
									</Button>
								</Box>
							</form>
						</DialogContent>
					</Paper>
				</div>
			</Slide>
		</Portal>
	);
});

const getFullAddress = (geoObject?: Partial<GeoObjectFullFragment>) => {
	const geoAddress: string[] = [];
	if (geoObject?.Region) geoAddress.push(geoObject.Region.Name);
	if (geoObject?.City) geoAddress.push(geoObject.City);
	if (geoObject?.Address) geoAddress.push(geoObject.Address);

	return geoAddress.length ? geoAddress.join(", ") : "";
};
