import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Button, List, ListItem, ListItemIcon, ListItemText, Typography } from "@material-ui/core";
import { IGeoObjectBlockProps, objectTypeSelectorItems } from "./types";
import useAppContext from "../../../contexts/AppContext";
import {
	AttachmentFullFragment,
	EAttachmentType,
	EGeoObjectStatus,
	EGeoObjectType,
	ELoginRole,
	UpsertGeoObjectInput,
	useUpsertGeoObjectMutation,
} from "@rsonav/protocol";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import { FullScreenPlaceholder } from "..";
import locale from "../../../helpers/strings";
import { Gallery, StandartTextInputController } from "../..";
import { geoOrgLabel } from "../../../helpers/geoOrgLabel";
import validUrl from "valid-url";
import { regExps } from "@rsonav/shared-config";
import { RecycledTypesSelector } from "./RecycledTypesSelector";
import { DatePicker } from "@material-ui/pickers";
import { ContainersCount } from "./ContainersCount";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { withYMaps, WithYMapsProps } from "react-yandex-maps";
import _ from "lodash";

interface IUpsertGeoFormProps extends IGeoObjectBlockProps {
	onClose: () => void;
}

export const UpsertGeoForm = withYMaps<IUpsertGeoFormProps>(
	(props) => {
		const { geoPosition, login, editCoords, updateForceRefetchTime } = useAppContext();
		const { geoObject, onClose, ymaps } = props as IUpsertGeoFormProps & WithYMapsProps;

		const [upsertObject, { loading }] = useUpsertGeoObjectMutation();

		const [formSent, setFormSent] = useState(false);

		const attachments = useRef<AttachmentFullFragment[]>([]);
		const [attachmentLoading, setAttachmentLoading] = useState(false);

		const canEdit = login?.Role === ELoginRole.Moderator || login?.Role === ELoginRole.Admin;

		const defaultValues = {
			Address: geoObject.Address || "",
			AllTime: geoObject.AllTime || true,
			AttachmentId: undefined,
			City: geoObject.City || "",
			Country: geoObject.City || "",
			RegionName: geoObject.Region?.Name || "",
			Containers: geoObject.Containers || 0,
			Description: geoObject.Description || "",
			Email: geoObject.Email || "",
			EndAt: geoObject.EndAt || undefined,
			Id: geoObject.Id || undefined,
			Lat: geoObject.Lat || geoPosition?.coords.latitude || 0,
			Long: geoObject.Long || geoPosition?.coords.longitude || 0,
			Org: geoObject.Org || "",
			Phone: geoObject.Phone || "",
			SpecialContainers: geoObject.SpecialContainers || 0,
			StartAt: geoObject.StartAt || undefined,
			Status: geoObject.Status || (canEdit ? EGeoObjectStatus.Approved : EGeoObjectStatus.New),
			Type: geoObject.Type || EGeoObjectType.Containers,
			URL: geoObject.URL || "",
			WorkingHours: geoObject.WorkingHours || "",
			RecycledType: geoObject.RecycledType?.map((t) => t.Type) || undefined,
		};

		const form = useForm<UpsertGeoObjectInput>({
			mode: "onBlur",
			reValidateMode: "onChange",
			defaultValues: defaultValues,
			shouldUnregister: false,
		});
		const { handleSubmit, errors, control } = form;

		useEffect(() => {
			if (
				editCoords &&
				(!defaultValues.Address ||
					form.getValues("Lat") !== editCoords[0] ||
					form.getValues("Long") !== editCoords[1])
			) {
				form.setValue("Lat", editCoords[0]);
				form.setValue("Long", editCoords[1]);
				ymaps
					.geocode(`${editCoords[0]},${editCoords[1]}`, { json: true, results: 1, kind: "house" })
					.then((response: any) => {
						if (
							response?.GeoObjectCollection?.featureMember &&
							response.GeoObjectCollection.featureMember.length > 0
						) {
							const found = response.GeoObjectCollection.featureMember[0].GeoObject;
							form.setValue("Address", found.name);
							const address = found.metaDataProperty.GeocoderMetaData.Address;
							const addressParts: Array<{ kind: string; name: String }> | undefined = address?.Components;
							const city = addressParts?.find((component) => component.kind === "locality");
							const region = _.findLast(addressParts, (component) => component.kind === "province");
							const country = addressParts?.find((component) => component.kind === "country");
							city && form.setValue("City", city.name);
							region && form.setValue("RegionName", region.name);
							country && form.setValue("Country", country.name);
							void form.trigger("Address");
						}
					})
					.catch((ex: any) => {
						console.warn("Error getting address:", JSON.stringify(ex));
					});
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [editCoords]);

		const wathchObjectType = form.watch("Type");
		const wathchObjectStart = form.watch("StartAt");

		const submit = (data: UpsertGeoObjectInput) => {
			if (!data.Id) {
				data.Id = undefined;
			}

			if (data.StartAt && data.EndAt) {
				data.StartAt = moment.utc(data.StartAt).local().startOf("day").utc().toISOString();
				data.EndAt = moment.utc(data.EndAt).local().endOf("day").utc().toISOString();
			}

			data.AttachmentId = attachments.current.map((a) => a.Id);
			data.Email = data.Email?.trim();

			upsertObject({ variables: { data } })
				.then((result) => {
					if (result.data?.upsertGeoObject.success) {
						setFormSent(true);
						updateForceRefetchTime();
					}
				})
				.catch((reason) => {
					console.warn("Error sending upsertGeoObject form:", reason);
				});
		};

		const onUpdateAttachments = useCallback(
			(newAttachments: AttachmentFullFragment[]) => (attachments.current = newAttachments),
			[],
		);

		const closeDialog = () => {
			form.reset();
			setFormSent(false);
			onClose();
		};

		if (formSent) {
			return (
				<FullScreenPlaceholder
					titleKey="upsertObject_finishTitle"
					messageKey={canEdit ? "upsertObject_finishEditMessage" : "upsertObject_finishMessage"}
					className="done"
					actionKey="close"
					action={closeDialog}
				/>
			);
		}

		return (
			<MuiPickersUtilsProvider utils={MomentUtils}>
				<form onSubmit={handleSubmit(submit)}>
					<Controller
						name="Type"
						defaultValue={null}
						control={control}
						render={(props) => (
							<List className="object-type-selector">
								{objectTypeSelectorItems.map((conf, index) => (
									<ListItem
										key={`object-${conf.value}`}
										button
										selected={props.value === conf.value}
										onClick={() => props.onChange(conf.value)}
									>
										<ListItemIcon className={conf.className}>{index + 1}</ListItemIcon>
										<ListItemText primary={locale[conf.labelKey]} />
									</ListItem>
								))}
							</List>
						)}
					/>
					<StandartTextInputController
						name="Address"
						multiline
						control={control}
						errorMessage={errors.Address?.message}
						label={locale.address}
						margin="normal"
						rules={{
							required: locale.upsertObject_addressRequired,
							maxLength: {
								value: 150,
								message: locale.maxLength,
							},
						}}
					/>
					<StandartTextInputController
						name="Description"
						control={control}
						errorMessage={errors.Description?.message}
						label={locale.description}
						margin="normal"
						rules={{
							maxLength: {
								value: 200,
								message: locale.maxLength,
							},
						}}
						multiline
						rows={5}
					/>
					<Gallery
						attachmentType={EAttachmentType.GeoObjectGallery}
						maxItems={10}
						acceptedFiles={["image/*"]}
						onUpdate={onUpdateAttachments}
						maxFileSize={2097152}
						addFileTip="add"
						compact
						objectId={geoObject.Id}
						onLoadingChange={setAttachmentLoading}
					/>
					<StandartTextInputController
						name="Org"
						control={control}
						errorMessage={errors.Org?.message}
						label={geoOrgLabel(wathchObjectType)}
						margin="normal"
						rules={{
							maxLength: {
								value: 200,
								message: locale.maxLength,
							},
						}}
						multiline
						rows={3}
					/>
					{wathchObjectType === EGeoObjectType.Stationary && (
						<StandartTextInputController
							name="WorkingHours"
							control={control}
							errorMessage={errors.WorkingHours?.message}
							label={locale.geo_workingHours}
							margin="normal"
							rules={{
								maxLength: {
									value: 200,
									message: locale.maxLength,
								},
							}}
							multiline
							rows={3}
						/>
					)}
					{wathchObjectType === EGeoObjectType.Temporary && (
						<Box className="info-block">
							<Typography variant="h5">{locale.geo_datesTitle}</Typography>
							<Controller
								name="StartAt"
								control={control}
								rules={{
									required: locale.upsertObject_startAtRequired,
								}}
								defaultValue={null}
								render={(props) => (
									<DatePicker
										inputVariant="filled"
										value={moment.utc(props.value).local()}
										onChange={(newDate) => props.onChange(newDate?.utc().toISOString())}
										label={locale.timeRangeStart}
										helperText={errors.StartAt?.message}
										error={!!errors.StartAt?.message}
										format="DD MMMM YYYY"
										margin="normal"
										fullWidth
									/>
								)}
							/>
							<Controller
								name="EndAt"
								control={control}
								rules={{
									required: locale.upsertObject_endAtRequired,
									validate: (value) =>
										moment(wathchObjectStart).isBefore(value) || locale.upsertObject_endAtInvalid,
								}}
								defaultValue={null}
								render={(props) => (
									<DatePicker
										inputVariant="filled"
										value={moment.utc(props.value).local()}
										onChange={(newDate) => props.onChange(newDate?.utc().toISOString())}
										label={locale.timeRangeEnd}
										helperText={errors.EndAt?.message}
										error={!!errors.EndAt?.message}
										format="DD MMMM YYYY"
										margin="normal"
										fullWidth
									/>
								)}
							/>
						</Box>
					)}
					{wathchObjectType === EGeoObjectType.Containers && (
						<Box className="info-block">
							<Typography variant="h5">{locale.upsertObject_containersCount}</Typography>
							<Controller
								name="Containers"
								control={control}
								render={({ value, onChange }) => (
									<ContainersCount
										value={value}
										onChange={onChange}
										titleKey="upsertObject_containersTitle"
									/>
								)}
							/>
							<Controller
								name="SpecialContainers"
								control={control}
								render={({ value, onChange }) => (
									<ContainersCount
										value={value}
										onChange={onChange}
										titleKey="upsertObject_specialContainersTitle"
									/>
								)}
							/>
						</Box>
					)}
					<Box className="info-block">
						<Typography variant="h5">{locale.upsertObject_contacts}</Typography>
						<StandartTextInputController
							name="Phone"
							control={control}
							errorMessage={errors.Phone?.message}
							label={locale.phoneNumber}
							margin="normal"
							rules={{
								pattern: {
									value: regExps.phone,
									message: locale.phoneError,
								},
							}}
							placeholder="+7 (999) 999 99 99"
							mask="+7 (999) 999 99 99"
						/>
						<StandartTextInputController
							name="Email"
							control={control}
							errorMessage={errors.Email?.message}
							label={locale.email}
							margin="normal"
							rules={{
								pattern: {
									value: regExps.email,
									message: locale.wrongEmail,
								},
							}}
							placeholder="example@mail.com"
						/>
						<StandartTextInputController
							name="URL"
							control={control}
							errorMessage={errors.URL?.message}
							label={locale.URL}
							margin="normal"
							rules={{
								validate: (value) => !value || !!validUrl.isWebUri(value) || locale.wrongURL,
							}}
							placeholder="https://example.com"
						/>
					</Box>
					<Controller
						name="RecycledType"
						defaultValue={null}
						control={control}
						render={({ value, onChange }) => <RecycledTypesSelector value={value} onChange={onChange} />}
					/>
					<Box className="MuiFormControl-marginNormal">
						<Button
							type="submit"
							variant="contained"
							color="primary"
							fullWidth
							disabled={Object.values(errors).length > 0 || loading || attachmentLoading}
						>
							{canEdit ? locale.save : locale.upsertObject_submit}
						</Button>
					</Box>
				</form>
			</MuiPickersUtilsProvider>
		);
	},
	true,
	["geocode"],
);
