import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { TLocale } from "@rsonav/shared-config";
import { InputLabel, Box } from "@material-ui/core";
import locale from "../../helpers/strings";
import Editor from "ckeditor5-custom-build/build/ckeditor";

export interface ICKEditorInputProps {
	onChange: (value: string) => void;
	onBlur: (value: string) => void;
	value: string;
	label?: keyof TLocale;
}
const editorConfig = {
	toolbar: {
		items: [
			"undo",
			"redo",
			"|",
			"heading",
			"|",
			"bold",
			"italic",
			"underline",
			"|",
			"bulletedList",
			"numberedList",
			"|",
			"insertTable",
			"tableRow",
			"tableColumn",
			"mergeTableCells",
			"|",
			"link",
			"mediaEmbed",
		],
	},
	language: "ru",
	table: {
		contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
	},
	heading: {
		options: [
			{ model: "paragraph", title: "Paragraph", class: "ck-heading_paragraph" },
			{ model: "heading1", view: "h1", title: "Heading 1", class: "ck-heading_heading1" },
			{ model: "heading2", view: "h2", title: "Heading 2", class: "ck-heading_heading2" },
			{ model: "heading3", view: "h3", title: "Heading 3", class: "ck-heading_heading3" },
			{ model: "heading4", view: "h4", title: "Heading 4", class: "ck-heading_heading4" },
			{ model: "heading5", view: "h5", title: "Heading 5", class: "ck-heading_heading5" },
		],
	},
};
export const CKEditorInput = (props: ICKEditorInputProps) => {
	const { value, onChange, onBlur } = props;

	const [editorInstance, setEditor] = useState<any>();

	useEffect(() => {
		if (editorInstance && !editorInstance.getData()) {
			editorInstance.setData(value);
		}
	}, [value, editorInstance]);

	return (
		<Box className="ckeditor-container">
			{props.label && <InputLabel>{locale[props.label]}</InputLabel>}

			<CKEditor
				editor={Editor}
				config={editorConfig}
				data={value}
				onReady={(editor: any) => {
					setEditor(editor);
				}}
				onChange={(_event: any, editor: any) => {
					const data = editor.getData();
					onChange(data);
				}}
				onBlur={(_event: any, editor: any) => {
					const data = editor.getData();
					onBlur(data);
				}}
			/>
		</Box>
	);
};
