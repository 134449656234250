import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import withAuth from "../../helpers/withAuth";
import { FullScreenPlaceholder, StandartView } from "../../components";
import { useGetMyFavoritesQuery } from "@rsonav/protocol";
import { FavoritesListItem } from "./FavoritesListItem";
import useAppContext from "../../contexts/AppContext";
import locale from "../../helpers/strings";

export const Favorites = withAuth(() => {
	const { geoPosition } = useAppContext();

	const { data, loading } = useGetMyFavoritesQuery({
		variables: { lat: geoPosition?.coords.latitude, long: geoPosition?.coords.longitude },
		fetchPolicy: "cache-and-network",
	});

	return (
		<StandartView header={<Typography variant="h4">{locale.profile_favoritesTitle}</Typography>}>
			<Box className="favorites">
				<Grid container justify="flex-start" className="list-container">
					{loading ? (
						new Array(10).fill(false).map((_, i) => <FavoritesListItem key={i} />)
					) : data?.getMyFavorites?.length ? (
						data.getMyFavorites.map((favorite) => (
							<FavoritesListItem favorite={favorite} key={favorite.Id} />
						))
					) : (
						<FullScreenPlaceholder messageKey="profile_favoritesEmpty" className="favorites" />
					)}
				</Grid>
			</Box>
		</StandartView>
	);
});
