import { Box, Typography } from "@material-ui/core";
import { ERecycledType } from "@rsonav/protocol";
import React, { useEffect, useState } from "react";
import { StandartCheckbox } from "..";
import { recycledTypesList } from "../../../helpers/recycledTypesList";
import locale from "../../../helpers/strings";

export interface IRecycledTypesSelectorProps {
	onChange: (value?: ERecycledType[]) => void;
	value?: ERecycledType[];
}

export const RecycledTypesSelector = (props: IRecycledTypesSelectorProps): JSX.Element => {
	const [value, setValue] = useState(props.value);

	useEffect(() => {
		props.onChange(value);
	}, [props, value]);

	const updateRecycledTypes = (checked: boolean, type: ERecycledType) => {
		setValue((prevState) => {
			if (checked) {
				if (!prevState?.includes(type)) {
					return (prevState || []).concat(type);
				} else {
					return prevState;
				}
			} else {
				return (prevState || []).filter((t) => t !== type);
			}
		});
	};

	const recycledChecked = (type: ERecycledType) => {
		if (!value) {
			return false;
		}
		return value.includes(type);
	};

	return (
		<Box className="recycled-types-selector">
			<Typography variant="h5">{locale.geo_recycledTypes}</Typography>
			{Object.entries(recycledTypesList).map(([key, val]) => (
				<StandartCheckbox
					key={`recycledType-${key}`}
					checked={recycledChecked(key as ERecycledType)}
					onChange={(event) => updateRecycledTypes(event.target.checked, key as ERecycledType)}
					name={`recycledType-${key}`}
					color="primary"
					labelKey={val.localeKey}
				/>
			))}
		</Box>
	);
};
