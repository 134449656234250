import { Button, Typography } from "@material-ui/core";
import { TLocale } from "@rsonav/shared-config";
import React from "react";
import locale from "../../helpers/strings";

export interface IFullScreenPlaceholderProps {
	titleKey?: keyof TLocale;
	messageKey?: keyof TLocale;
	actionKey?: keyof TLocale;
	className?: string;
	action?: () => void;
}

export const FullScreenPlaceholder = (props: IFullScreenPlaceholderProps) => {
	return (
		<div className={`placeholder-container ${props.className}`}>
			<div className="placeholder-content">
				{props.titleKey && <Typography variant="h4">{locale[props.titleKey]}</Typography>}
				{props.messageKey && <Typography variant="body1">{locale[props.messageKey]}</Typography>}
			</div>
			{props.actionKey && props.action && (
				<Button variant="contained" fullWidth onClick={props.action}>
					{locale[props.actionKey]}
				</Button>
			)}
		</div>
	);
};
