import React, { useMemo, useState } from "react";
import { EGeoObjectStatus, EGeoObjectType, ELoginRole, ERecycledType, GeoObjectFilters } from "@rsonav/protocol";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	IconButton,
	Typography,
	DialogTitle,
	Checkbox,
	TextField,
	Chip,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { recycledTypesList } from "../../helpers/recycledTypesList";
import locale from "../../helpers/strings";
import { badgeConfigs } from "../../helpers/badgeConfigs";
import { StandartCheckbox } from ".";
import { geoStatusesList } from "../../helpers/geoStatusesList";
import Autocomplete from "@material-ui/lab/Autocomplete";
import useAppContext from "../../contexts/AppContext";

export interface IFiltersDialogProps {
	open: boolean;
	onClose: () => void;
	filters: GeoObjectFilters;
	defaultFilters: GeoObjectFilters;
	setFilters: (filters: GeoObjectFilters) => void;
}

export const FiltersDialog = (props: IFiltersDialogProps) => {
	const { login, filtersValues } = useAppContext();

	const [filters, setFilters] = useState(props.filters);

	const recycledTypeFilters = useMemo(() => {
		let f = Object.entries(recycledTypesList).map(([type, conf]) => ({ Type: type as ERecycledType, ...conf }));
		if (filtersValues) {
			f = f.filter((conf) => filtersValues.RecycledTypes.includes(conf.Type));
		}
		return f;
	}, [filtersValues]);

	const objectTypeFilters = useMemo(() => {
		let f = Object.entries(badgeConfigs).map(([type, conf]) => ({ Type: type as EGeoObjectType, ...conf }));
		if (filtersValues) {
			f = f.filter((conf) => filtersValues.ObjectTypes.includes(conf.Type));
		}
		return f;
	}, [filtersValues]);

	const updateRecycledTypes = (checked: boolean, type: ERecycledType) => {
		setFilters((prevState) => {
			if (checked) {
				if (!prevState.RecycledType?.includes(type)) {
					return { ...prevState, RecycledType: (prevState.RecycledType || []).concat(type) };
				} else {
					return prevState;
				}
			} else {
				return { ...prevState, RecycledType: (prevState.RecycledType || []).filter((t) => t !== type) };
			}
		});
	};

	const updateObjectTypes = (checked: boolean, type: EGeoObjectType) => {
		setFilters((prevState) => {
			if (checked) {
				if (!prevState.Type?.includes(type)) {
					return { ...prevState, Type: (prevState.Type || []).concat(type) };
				} else {
					return prevState;
				}
			} else {
				return { ...prevState, Type: (prevState.Type || []).filter((t) => t !== type) };
			}
		});
	};

	const updateObjectStatuses = (checked: boolean, type: EGeoObjectStatus) => {
		setFilters((prevState) => {
			if (checked) {
				if (!prevState.Status?.includes(type)) {
					return { ...prevState, Status: (prevState.Status || []).concat(type) };
				} else {
					return prevState;
				}
			} else {
				return { ...prevState, Status: (prevState.Status || []).filter((t) => t !== type) };
			}
		});
	};

	const recycledChecked = (type: ERecycledType) => {
		if (!filters.RecycledType) {
			return false;
		}
		return filters.RecycledType.includes(type);
	};

	const typeChecked = (type: EGeoObjectType) => {
		if (!filters.Type) {
			return false;
		}
		return filters.Type.includes(type);
	};

	const statusChecked = (status: EGeoObjectStatus) => {
		if (!filters.Status) {
			return false;
		}
		return filters.Status.includes(status);
	};

	const applyFilters = () => {
		props.setFilters(filters);
		props.onClose();
	};

	const resetFilters = () => {
		props.setFilters(props.defaultFilters);
		setFilters(props.defaultFilters);
		props.onClose();
	};

	return (
		<Dialog onClose={props.onClose} className="filters-dialog" open={props.open}>
			<DialogTitle disableTypography>
				<div className="container">
					<Box className="toolbar">
						<div className="title">
							<Typography variant="h4" display="inline">
								{locale.search_filters}
							</Typography>
							<Button color="default" variant="text" onClick={resetFilters}>
								{locale.reset}
							</Button>
						</div>
						<IconButton
							onClick={() => {
								props.onClose();
							}}
							className="btn-close"
						>
							<CloseIcon />
						</IconButton>
					</Box>
				</div>
			</DialogTitle>
			<DialogContent>
				<Box>
					<Typography variant="h5">{locale.geo_recycledTypes}</Typography>
					{recycledTypeFilters.map((conf) => (
						<StandartCheckbox
							key={`recycledType-${conf.Type}`}
							checked={recycledChecked(conf.Type)}
							onChange={(event) => updateRecycledTypes(event.target.checked, conf.Type)}
							name={`recycledType-${conf.Type}`}
							color="primary"
							labelKey={conf.localeKey}
						/>
					))}
				</Box>
				<Box>
					<Typography variant="h5">{locale.geo_objectType}</Typography>
					{objectTypeFilters.map((conf) => (
						<StandartCheckbox
							key={`objectType-${conf.Type}`}
							checked={typeChecked(conf.Type)}
							onChange={(event) => updateObjectTypes(event.target.checked, conf.Type)}
							name={`objectType-${conf.Type}`}
							color="primary"
							labelKey={conf.labelKey}
						/>
					))}
				</Box>
				{!!filtersValues?.Cities.length && (
					<Box>
						<Typography variant="h5">{locale.geo_city}</Typography>
						<Autocomplete
							multiple
							id="checkboxes-tags-demo"
							options={filtersValues.Cities}
							value={filters.City || undefined}
							disableCloseOnSelect
							renderOption={(option, { selected }) => (
								<div className="standart-checkbox primary">
									<Checkbox
										icon={<i className="icon icon-check" />}
										checkedIcon={<i className="icon icon-check" />}
										checked={selected}
										color="primary"
									/>
									{option}
								</div>
							)}
							renderInput={(params) => (
								<TextField
									{...params}
									variant="filled"
									label={locale.geo_cityLabel}
									placeholder={locale.geo_cityPlaceholder}
									color="primary"
								/>
							)}
							renderTags={(value, getTagProps) =>
								value.map((option, index) => (
									<Chip color="primary" label={option} {...getTagProps({ index })} />
								))
							}
							onChange={(_evt, value) => {
								setFilters((prevState) => ({ ...prevState, City: value }));
							}}
							noOptionsText={locale.nothingFound}
						/>
					</Box>
				)}
				{(login?.Role === ELoginRole.Admin || login?.Role === ELoginRole.Moderator) && (
					<Box>
						<Typography variant="h5">{locale.geo_objectStatus}</Typography>
						{Object.entries(geoStatusesList).map(([key, val]) => (
							<StandartCheckbox
								key={`objectType-${key}`}
								checked={statusChecked(key as EGeoObjectStatus)}
								onChange={(event) =>
									updateObjectStatuses(event.target.checked, key as EGeoObjectStatus)
								}
								name={`objectType-${key}`}
								color="primary"
								labelKey={val.localeKey}
							/>
						))}
					</Box>
				)}
			</DialogContent>
			<DialogActions>
				<Button fullWidth color="primary" variant="contained" onClick={applyFilters}>
					{locale.apply}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
