import { Icon, TextField, TextFieldProps } from "@material-ui/core";
import React, { useMemo, useState } from "react";
import InputMask, { Props } from "react-input-mask";

export type TPhoneMaskInputProps = Omit<Props, "pattern">;

export type TStandartTextInputProps = Omit<TextFieldProps, "onChange" | "value"> & {
	onChange: (value: string, isValid: boolean) => void;
	mask?: string;
	maskChar?: string | null;
	pattern?: RegExp;
	errorMessage?: string;
	value: string;
};

const CYRYLLIC_FORMAT_CHARS = {
	"9": "[0-9]",
	a: "[A-Za-z]", // латинская буква а
	б: "[А-Яа-я]",
	"*": "[A-Za-z0-9А-Яа-я]",
};

export const StandartTextInput = (props: TStandartTextInputProps) => {
	const { onChange, mask, maskChar, pattern, errorMessage, ...otherProps } = props;
	const [error, setError] = useState(false);

	const checkValue = (value: string) => {
		let isValid = true;
		if (otherProps.required && value.trim().length === 0) {
			isValid = false;
			setError(!isValid);
		} else if (pattern) {
			isValid = pattern.test(value) || (!otherProps.required && value.length === 0);
			setError(!isValid);
		}

		if (isValid && error) {
			setError(false);
		}
		onChange(value, isValid);
	};

	const onBlur = () => checkValue(otherProps.value?.trim());

	const InputComponent = useMemo(
		() => (inputComponentProps: any) => (
			<InputMask {...inputComponentProps} mask={mask} maskChar={maskChar} formatChars={CYRYLLIC_FORMAT_CHARS} />
		),
		[mask, maskChar],
	);

	const showError = error || otherProps.error;

	return (
		<TextField
			fullWidth
			variant="filled"
			onBlur={onBlur}
			{...otherProps}
			error={showError}
			helperText={showError ? errorMessage : undefined}
			onChange={(e) => {
				const v = e.target.value;
				return checkValue(v);
			}}
			InputProps={{
				inputComponent: mask ? InputComponent : undefined,
				endAdornment: showError && <Icon className="icon icon--Attention" fontSize="small" color="error" />,
				...otherProps.InputProps,
			}}
			InputLabelProps={{ shrink: true, ...otherProps.InputLabelProps }}
		/>
	);
};
