import { Button, ButtonGroup, FormControl, FormHelperText, FormLabel } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import locale from "../../../helpers/strings";
import { TSwitchBarProps } from "./types";

export function SwitchBar<ItemT = any>(props: TSwitchBarProps<ItemT>): JSX.Element {
	const {
		labelKey,
		items,
		onItemsChange,
		multiSelect,
		selectedIndex,
		selectedItems,
		errorKey,
		...controlProps
	} = props;

	let selectedInit = selectedIndex || multiSelect ? [] : [0];

	if (selectedItems) {
		selectedInit = [];
		for (const selectedItem of selectedItems) {
			const idx = items.findIndex((item) => item.value === selectedItem);
			if (idx > -1) {
				selectedInit.push(idx);
			}
		}
	}

	const [selected, setSelected] = useState<number[]>(selectedInit);

	const updateValues = (index: number) => {
		if (multiSelect) {
			setSelected((prevState) => {
				const idx = prevState.indexOf(index);
				if (idx > -1) {
					prevState.splice(index, 1);
					return [...prevState];
				} else {
					return [...prevState, index];
				}
			});
		} else {
			setSelected([index]);
		}
	};

	useEffect(() => {
		onItemsChange && onItemsChange(selected.map((index) => items[index].value));
	}, [items, onItemsChange, selected]);

	return (
		<FormControl {...controlProps}>
			{labelKey && (
				<FormLabel component="legend" className="MuiSwitchBarLabel">
					{locale[labelKey]}
				</FormLabel>
			)}
			<ButtonGroup fullWidth>
				{items.map((item, idx) => (
					<Button
						color={controlProps.color}
						key={`sw-${idx}`}
						variant={selected.indexOf(idx) > -1 ? "contained" : "outlined"}
						onClick={() => updateValues(idx)}
						disableElevation
						className={item.className}
					>
						{locale[item.labelKey]}
					</Button>
				))}
			</ButtonGroup>
			{errorKey && <FormHelperText>{locale[errorKey]}</FormHelperText>}
		</FormControl>
	);
}
