import React, { useCallback, useEffect, useState } from "react";
import * as firebase from "firebase/app";
import "firebase/auth";
import { Box, Button, CircularProgress } from "@material-ui/core";
import { StandartTextInput } from ".";
import { EClientPlatform, useSignUpMutation } from "@rsonav/protocol";
import useAppContext from "../contexts/AppContext";
import { authStatusCode, TLocale } from "@rsonav/shared-config";
import locale from "../helpers/strings";

declare global {
	interface Window {
		grecaptcha: any;
	}
}
interface ISignUpState {
	localeKey: keyof TLocale;
	error: boolean;
	loading: boolean;
	confirmRes?: firebase.auth.ConfirmationResult;
}

export interface ISignUpFormProps {
	authDefaultMessageKey?: keyof TLocale;
	authCodeSendedKey?: keyof TLocale;
	onSignUpFinish?: () => void;
}

const PHONE_REGEXP = /^\+7 \([0-9]{3}\) [0-9]{3} [0-9]{2} [0-9]{2}$/;
const CODE_REGEXP = /^[0-9]{6}$/;
const RESEND_TIMEOUT = 60;

export const SignUpForm = (props: ISignUpFormProps) => {
	const [phoneNumber, setPhoneNumber] = useState<string>("");
	const [confirmCode, setConfirmCode] = useState<string>("");
	const [captcha, setCaptcha] = useState<firebase.auth.RecaptchaVerifier>();
	const [countdown, setCountdown] = useState(RESEND_TIMEOUT);

	const [signUpState, setSignUpState] = useState<ISignUpState>({
		loading: false,
		error: false,
		localeKey: props.authDefaultMessageKey || "auth_defaultMessage",
	});

	const [firebaseAppAuth, setFirebasAppAuth] = useState<firebase.auth.Auth>();

	const [signUp, { data }] = useSignUpMutation();

	const { session, setSession, firebaseApp, setLogin } = useAppContext();

	useEffect(() => {
		if (firebaseApp) {
			setFirebasAppAuth(firebaseApp.auth());
			setCaptcha(
				new firebase.auth.RecaptchaVerifier("recaptcha-container", {
					size: "invisible",
				}),
			);
		}
	}, [firebaseApp]);

	useEffect(() => {
		let countdownTimeout: NodeJS.Timeout;
		if (signUpState.confirmRes && countdown > 0) {
			countdownTimeout = setTimeout(() => {
				setCountdown((prevState) => prevState - 1);
			}, 1000);
		}
		if (countdown === 0) {
			window.grecaptcha.reset();
		}
		return () => {
			clearTimeout(countdownTimeout);
		};
	}, [signUpState.confirmRes, countdown]);

	const verifyCode = useCallback(async () => {
		if (!signUpState.confirmRes) {
			return;
		}
		setSignUpState((prevState) => ({ ...prevState, error: false, loading: true, localeKey: "auth_codeChecking" }));
		try {
			const fbResult = await signUpState.confirmRes.confirm(confirmCode);
			if (fbResult.user) {
				const authToken = await fbResult.user.getIdToken();
				await signUp({ variables: { data: { FirebaseToken: authToken, Platform: EClientPlatform.Web } } });
			}
			setSignUpState((prevState) => ({ ...prevState, error: false, loading: false }));
		} catch (ex) {
			const localeKey: keyof TLocale = (ex.code && authStatusCode.get(ex.code)?.localeKey) || "auth_unknown";
			setSignUpState((prevState) => ({ ...prevState, localeKey, error: true, loading: false }));
		}
	}, [confirmCode, signUp, signUpState.confirmRes]);

	useEffect(() => {
		if (CODE_REGEXP.test(confirmCode)) {
			verifyCode();
		}
	}, [confirmCode, verifyCode]);

	if (session) {
		props.onSignUpFinish && props.onSignUpFinish();
		return (
			<Box>
				<h1>{locale.formatString(locale.web_clientGreeting)}</h1>
			</Box>
		);
	}

	const sendCode = async () => {
		if (!captcha || !firebaseAppAuth) {
			return;
		}
		setSignUpState((prevState) => ({ ...prevState, localeKey: "auth_codeSending", error: false, loading: true }));
		try {
			const fbResponse = await firebaseAppAuth.signInWithPhoneNumber(phoneNumber, captcha);
			setSignUpState((prevState) => ({
				...prevState,
				localeKey: props.authCodeSendedKey || "auth_codeSended",
				error: false,
				loading: false,
				confirmRes: fbResponse,
			}));
			setCountdown(RESEND_TIMEOUT);
		} catch (ex) {
			const localeKey: keyof TLocale = (ex.code && authStatusCode.get(ex.code)?.localeKey) || "auth_unknown";
			setSignUpState((prevState) => ({ ...prevState, localeKey, error: true, loading: false }));
		}
	};

	if (data?.signUp.data) {
		setSession({ auth: { token: data?.signUp.data.token, id: data?.signUp.data.authId } });
		setLogin(data.signUp.data.Login);
	}

	return (
		<Box className="sign-up-form">
			<Box className={signUpState.error ? "error-text" : undefined}>
				{locale.formatString<string>(locale[signUpState.localeKey], phoneNumber)}
			</Box>
			{!signUpState.confirmRes && (
				<StandartTextInput
					value={phoneNumber}
					label={locale.phoneNumber}
					onChange={(value) => setPhoneNumber(value)}
					mask="+7 (999) 999 99 99"
					pattern={PHONE_REGEXP}
					margin="normal"
					disabled={signUpState.loading}
					placeholder="+7 (___) ___ __ __"
					errorMessage={locale.phoneError}
					variant="filled"
				/>
			)}
			{!!signUpState.confirmRes && (
				<StandartTextInput
					className="confirm-code"
					value={confirmCode}
					label={locale.auth_codeLabel}
					onChange={(value) => setConfirmCode(value)}
					mask="999999"
					pattern={CODE_REGEXP}
					margin="normal"
					disabled={signUpState.loading}
					placeholder="______"
					errorMessage={locale.auth_phoneCodeError}
				/>
			)}
			<Box className="MuiFormControl-marginNormal">
				<Button
					onClick={sendCode}
					disabled={
						signUpState.loading ||
						!PHONE_REGEXP.test(phoneNumber) ||
						(signUpState.confirmRes && countdown > 0)
					}
					endIcon={
						!signUpState.confirmRes && signUpState.loading && <CircularProgress size={24} color="inherit" />
					}
					variant={signUpState.confirmRes ? "outlined" : "contained"}
					fullWidth
				>
					{!signUpState.confirmRes
						? locale.auth_sendCode
						: countdown > 0
						? locale.formatString<string>(locale.auth_sendCodeAgainTimeout, countdown.toString())
						: locale.auth_sendCodeAgain}
				</Button>
			</Box>
			<div id="recaptcha-container"></div>
		</Box>
	);
};
