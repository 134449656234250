import React, { useState } from "react";
import { List, ListItem, ListItemText, Typography } from "@material-ui/core";
import withAuth from "../../helpers/withAuth";
import { StandartView } from "../../components";
import locale from "../../helpers/strings";
import { ERequestType } from "@rsonav/protocol";
import { requestTypes } from "./constants";
import { UpsertRequestDialog } from "./UpsertRequestDialog";

export const Support = withAuth(() => {
	const [requestType, setRequestType] = useState<ERequestType>();
	const [policyOpened, setPolicyOpened] = useState(false);

	return (
		<StandartView
			header={<Typography variant="h4">{locale.support_chooseRequestType}</Typography>}
			policyOpened={policyOpened}
		>
			<List className="request-type-selector">
				{Object.entries(requestTypes).map(([type, conf]) => (
					<ListItem
						key={`request-${type}`}
						button
						selected={requestType === (type as ERequestType)}
						onClick={() => setRequestType(type as ERequestType)}
					>
						<ListItemText primary={locale[conf.localeKey]} />
						<i className={`icon icon-${conf.iconClass}`} />
					</ListItem>
				))}
			</List>
			<UpsertRequestDialog
				requestType={requestType}
				onClose={() => setRequestType(undefined)}
				open={!!requestType}
				onOpenPolicy={() => setPolicyOpened(true)}
			/>
		</StandartView>
	);
});
