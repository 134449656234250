import React from "react";
import { Switch, Route } from "react-router-dom";
import { About, Favorites, News, SignUp, Support } from "./views";

const Router = () => {
	return (
		<Switch>
			<Route path="/signup/:popup(geo)?/:id?">
				<SignUp />
			</Route>
			<Route path="/favorites/:popup(geo)?/:id?">
				<Favorites />
			</Route>
			<Route path="/about/:popup(geo)?/:id?">
				<About />
			</Route>
			<Route path="/policy">
				<News policyOpened />
			</Route>
			<Route path="/support/:popup(geo)?/:id?">
				<Support />
			</Route>
			<Route path="/geo/add">
				<News />
			</Route>
			<Route path="/:popup(geo)?/:id?">
				<News />
			</Route>
		</Switch>
	);
};

export default Router;
