import React, { useEffect } from "react";
import { EArticleType, useGetOneArticleLazyQuery, ArticleForListFragment, ELoginRole } from "@rsonav/protocol";
import { Box, Chip, Dialog, DialogContent, IconButton, Typography } from "@material-ui/core";
import locale from "../../helpers/strings";
import useAppContext from "../../contexts/AppContext";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import EditIcon from "@material-ui/icons/Edit";
import { articleTypes } from "./constants";

export interface IViewArticleDialogProps {
	open: boolean;
	onClose: () => void;
	article?: ArticleForListFragment;
	onEdit: (article: ArticleForListFragment) => void;
}

export const ViewArticleDialog = (props: IViewArticleDialogProps) => {
	const { article, open, onClose, onEdit } = props;

	const { login } = useAppContext();

	const [getArticle, { data }] = useGetOneArticleLazyQuery({
		fetchPolicy: "cache-and-network",
	});

	useEffect(() => {
		if (article) {
			getArticle({ variables: { id: article.Id } });
		}
	}, [getArticle, article]);

	if (!article) {
		return null;
	}

	const fetchedArticle = data?.getOneArticle;

	return (
		<Dialog onClose={onClose} className="view-article-dialog" open={open}>
			<div
				className={`MuiDialogTitle-root ${article.PromoAttachment ? "title-bg" : ""}`}
				style={{ backgroundImage: `url(${article.PromoAttachment?.Url}` }}
			>
				<div className="container">
					<Box className="toolbar">
						<IconButton onClick={onClose}>
							<i className="icon icon-arrow-left" />
						</IconButton>
						{article.Type !== EArticleType.News && (
							<Chip label={locale[articleTypes[article.Type]]} className="article-badge" />
						)}
						{(login?.Role === ELoginRole.Admin || login?.Role === ELoginRole.Moderator) && (
							<IconButton
								onClick={() => {
									onClose();
									onEdit(fetchedArticle || article);
								}}
								className="btn-add"
							>
								<EditIcon />
							</IconButton>
						)}
					</Box>
					<Box>
						<Box>
							<Typography variant="h3">{article.Header}</Typography>
						</Box>
						<Box>
							<Typography variant="subtitle1">
								{moment
									.utc(article.CreatedAt)
									.utcOffset(180, true)
									.local()
									.format("DD MMMM YYYY, HH:mm")}
							</Typography>
						</Box>
					</Box>
				</div>
			</div>
			<DialogContent>
				{fetchedArticle ? (
					<div className="article-content" dangerouslySetInnerHTML={{ __html: fetchedArticle.Body }} />
				) : (
					<div className="placeholder">
						<Skeleton />
						<Skeleton />
						<Skeleton className="medium" />
						<Skeleton />
						<Skeleton className="small" />
						<Skeleton />
						<Skeleton className="medium" />
						<Skeleton className="small" />
						<Skeleton />
						<Skeleton className="small" />
					</div>
				)}
			</DialogContent>
		</Dialog>
	);
};
