import React, { useCallback, useEffect, useRef } from "react";
import { Button, Grid } from "@material-ui/core";
import locale from "../../../../helpers/strings";
import useAppContext from "../../../../contexts/AppContext";
import {
	useUpsertArticleMutation,
	EAttachmentType,
	UpsertArticleInput,
	EArticleType,
	AttachmentFullFragment,
	useGetOneArticleQuery,
	GetOneArticleDocument,
} from "@rsonav/protocol";
import { Controller, useForm } from "react-hook-form";
import { Gallery } from "../../..";
import { CKEditorInput } from "../../CKEditorInput";
import { ABOUT_ID, mergeKeepShape } from "@rsonav/shared-config";

export interface IUpsertAboutTabProps {
	onLoading: (loading: boolean) => void;
}

const defaultValues: UpsertArticleInput = {
	Id: ABOUT_ID,
	Header: "About",
	Body: "",
	Type: EArticleType.Special,
	AttachmentId: [],
};

export const UpsertAboutTab = (props: IUpsertAboutTabProps) => {
	const { onLoading } = props;

	const { login } = useAppContext();
	const form = useForm<UpsertArticleInput>({
		mode: "onBlur",
		reValidateMode: "onChange",
		defaultValues,
	});

	const [upsertArticle, { loading: upsertingArticle }] = useUpsertArticleMutation({
		refetchQueries: [{ query: GetOneArticleDocument, variables: { id: "-1" } }],
	});

	const { data, loading } = useGetOneArticleQuery({ variables: { id: ABOUT_ID } });

	const attachments = useRef<AttachmentFullFragment[]>([]);
	const onUpdateAttachments = useCallback(
		(newAttachments: AttachmentFullFragment[]) => (attachments.current = newAttachments),
		[],
	);

	const { control, handleSubmit, setValue } = form;

	const fetchedArticle = data?.getOneArticle;

	useEffect(() => {
		if (!fetchedArticle) {
			return;
		}
		setValue("Body", fetchedArticle.Body);
	}, [setValue, fetchedArticle]);

	useEffect(() => {
		onLoading(loading || upsertingArticle);
	}, [loading, onLoading, upsertingArticle]);

	if (!login) {
		return null;
	}

	const submit = (formData: UpsertArticleInput) => {
		const data = mergeKeepShape(defaultValues, formData);
		upsertArticle({
			variables: { data },
			awaitRefetchQueries: true,
		});
	};

	return (
		<Grid container spacing={2} className="upsert-about-dialog">
			<Grid item xs={12} md={8} lg={9}>
				<Controller
					name="Body"
					control={control}
					rules={{
						required: locale.required,
					}}
					render={({ value, onChange, onBlur }) => (
						<CKEditorInput
							value={value}
							onChange={onChange}
							onBlur={onBlur}
							label="news_upsertArticleBody"
						/>
					)}
				/>
			</Grid>
			<Grid item xs={12} md={4} lg={3} className="right-col">
				<Gallery
					attachmentType={EAttachmentType.ArticleContent}
					maxItems={10}
					acceptedFiles={["image/*"]}
					onUpdate={onUpdateAttachments}
					objectId={ABOUT_ID}
					maxFileSize={2097152}
					addFileTip="news_upsertAddImage"
					label="news_upsertImagesLabel"
				/>
				<Button autoFocus onClick={handleSubmit(submit)} color="primary" variant="contained">
					{locale.save}
				</Button>
			</Grid>
		</Grid>
	);
};
