import React from "react";
import { SignUpForm, StandartView } from "../components";
import useAppContext from "../contexts/AppContext";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { TLocale } from "@rsonav/shared-config";
import { Box, IconButton } from "@material-ui/core";
import locale from "../helpers/strings";

export interface IAuthLocation {
	authMessageKey?: keyof TLocale;
	successRedirect?: string;
}
export const SignUp = () => {
	const { session } = useAppContext();

	const state = useLocation<IAuthLocation>().state;

	const history = useHistory();

	if (session) {
		if (state?.successRedirect) {
			return <Redirect to={state.successRedirect} />;
		}
		return <Redirect to="/" />;
	}

	const goBack = () => {
		if (state?.successRedirect) {
			history.goBack();
		} else {
			history.push("/");
		}
	};

	return (
		<StandartView hideHeader hideMenu hideFooter className="sign-up">
			<Box>
				<IconButton onClick={goBack}>
					<i className="icon icon-arrow-left" />
				</IconButton>
			</Box>
			<Box>
				<img className="logo" src="./logo.png" alt={locale.about_name} />
			</Box>
			<SignUpForm authDefaultMessageKey={state?.authMessageKey} />
		</StandartView>
	);
};
