import { ApolloError } from "@apollo/client";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import JwtDecode from "jwt-decode";
import { IConfig, loadConfig } from "@rsonav/shared-config";
import firebase from "firebase";
import "firebase/auth";
import {
	FiltersValues,
	LoginFullFragment,
	RegionFullFragment,
	useGetAllRegionsLazyQuery,
	useGetFiltersQuery,
	useGetOneLoginLazyQuery,
	useSetHomeRegionMutation,
} from "@rsonav/protocol";
import { withYMaps, WithYMapsProps } from "react-yandex-maps";
import { useCookies } from "react-cookie";
import moment from "moment";
import _ from "lodash";
interface IAppContextData {
	loginId: string;
}

interface IAppContextProps {
	auth?: {
		token: string;
		id?: number;
		resetSessonNum?: boolean;
	};
	data?: IAppContextData;
	error?: ApolloError;
}

export interface IAppContext {
	session?: IAppContextProps;
	setSession: (data: IAppContextProps, save?: boolean) => Promise<void>;
	clearSession: () => void;
	updateErrorCode: (code?: string | null) => void;
	closePannel: () => void;
	getErrorCode: () => string | null | undefined;
	setLogin: (login: LoginFullFragment) => void;
	sharedConfig: IConfig;
	firebaseApp: firebase.app.App;
	geoPosition?: Position;
	login?: LoginFullFragment | null;
	forceRefetchTime?: number;
	updateForceRefetchTime: () => void;
	editCoords?: [number, number];
	setEditCoords: (newCoords: [number, number] | undefined) => void;
	setLocalRegion: (login: RegionFullFragment) => void;
	filtersValues?: FiltersValues;
	regions?: RegionFullFragment[];
	curRegion?: RegionFullFragment;
	localRegion?: RegionFullFragment;
}

interface IUseAppContextProvider {
	children: ReactElement;
	updateAuthToken: (token: string | null) => void;
	token?: string | null;
	getErrorCode: () => string | null | undefined;
	updateErrorCode: (code?: string | null) => void;
	closePannel: () => void;
}

const sharedConfig = loadConfig(process.env.REACT_APP_CHANNEL || "local");
const firebaseApp = firebase.initializeApp(sharedConfig.firebaseWeb);

const AppContext = React.createContext<IAppContext>({
	setSession: async () => undefined,
	clearSession: () => undefined,
	updateErrorCode: () => undefined,
	closePannel: () => undefined,
	getErrorCode: () => undefined,
	setLogin: () => undefined,
	updateForceRefetchTime: () => undefined,
	setEditCoords: () => undefined,
	setLocalRegion: () => undefined,
	sharedConfig,
	firebaseApp,
});

const UseAppContextProvider = withYMaps(
	(props: IUseAppContextProvider) => {
		const {
			children,
			updateAuthToken,
			token,
			getErrorCode,
			updateErrorCode,
			closePannel,
			ymaps,
		} = props as IUseAppContextProvider & WithYMapsProps;

		const [cookies, setCookie] = useCookies();
		const [getLoginData, { data: loginData }] = useGetOneLoginLazyQuery();
		const { data: filters } = useGetFiltersQuery();
		const [getRegionData, { data: regions }] = useGetAllRegionsLazyQuery({ fetchPolicy: "cache-and-network" });
		const [setHomeRegion] = useSetHomeRegionMutation();

		let session: IAppContextProps | undefined;
		if (token) {
			session = { auth: { token }, data: JwtDecode<IAppContextData>(token) };
		}

		useEffect(() => {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition((position) => {
					setAppContext((prev) => ({ ...prev, geoPosition: position }));
				});
			}
		}, []);

		const setSession = async (data: IAppContextProps, save: boolean = true) => {
			if (data.auth) {
				updateAuthToken(data.auth.token);
				data.data = JwtDecode<IAppContextData>(data.auth.token);
			}
			setAppContext((prev) => ({ ...prev, session: data }));
		};

		const clearSession = () => {
			updateAuthToken(null);
			firebaseApp.auth().signOut();
			setAppContext((prev) => ({ ...prev, session: undefined, login: undefined, regions: undefined }));
		};

		const setLogin = (login?: LoginFullFragment | null) => {
			setAppContext((prev) => ({
				...prev,
				login,
				regions: prev.login?.Id === login?.Id ? prev.regions : undefined,
			}));
		};

		const updateForceRefetchTime = () => {
			setAppContext((prev) => ({ ...prev, forceRefetchTime: Date.now() }));
		};

		const setEditCoords = (newCoords: [number, number] | undefined) => {
			setAppContext((prev) => ({ ...prev, editCoords: newCoords }));
		};

		const setLocalRegion = (region?: RegionFullFragment | null) => {
			if (region) {
				setCookie("localRegion", region.Id, { expires: moment().add(2, "weeks").toDate() });
				setAppContext((prev) => ({ ...prev, localRegion: region }));
			}
		};

		const [appContext, setAppContext] = useState<IAppContext>({
			setSession,
			clearSession,
			getErrorCode,
			updateErrorCode,
			closePannel,
			setLogin,
			updateForceRefetchTime,
			setEditCoords,
			setLocalRegion,
			sharedConfig,
			session,
			firebaseApp,
		});

		useEffect(() => {
			if (!appContext.login && appContext?.session?.data?.loginId) {
				getLoginData({ variables: { id: appContext?.session?.data?.loginId } });
			}
		}, [getLoginData, appContext]);

		useEffect(() => {
			if (!appContext.session || !loginData?.getOneLogin) {
				return;
			}
			if (!appContext.login) {
				setAppContext((prev) => ({ ...prev, login: loginData?.getOneLogin, regions: undefined }));
			}
		}, [loginData, appContext]);

		useEffect(() => {
			if (filters?.getFilters) {
				setAppContext((prev) => ({ ...prev, filtersValues: filters.getFilters }));
			}
		}, [filters]);

		useEffect(() => {
			if (!appContext.regions) {
				getRegionData();
			}
		}, [getRegionData, appContext.regions]);

		useEffect(() => {
			if (regions?.getAllRegions?.length) {
				const region = regions.getAllRegions.find((r) => r.Id === cookies.localRegion);
				setAppContext((prev) => ({
					...prev,
					regions: regions.getAllRegions,
					localRegion: region || undefined,
				}));
			}
		}, [regions, appContext.login, cookies.localRegion]);

		useEffect(() => {
			if (!appContext.geoPosition || !ymaps || !appContext.regions) {
				return;
			}

			ymaps
				.geocode(`${appContext.geoPosition.coords.latitude},${appContext.geoPosition.coords.longitude}`, {
					json: true,
					results: 1,
					kind: "house",
				})
				.then((response: any) => {
					if (
						response?.GeoObjectCollection?.featureMember &&
						response.GeoObjectCollection.featureMember.length > 0
					) {
						const found = response.GeoObjectCollection.featureMember[0].GeoObject;
						const address = found.metaDataProperty.GeocoderMetaData.Address;
						const addressParts: Array<{ kind: string; name: String }> | undefined = address?.Components;
						const regionName = _.findLast(addressParts, (component) => component.kind === "province");
						const region = appContext.regions?.find((r) => r.Name === regionName?.name);

						if (region) {
							setAppContext((prev) => ({ ...prev, curRegion: region }));
							if (!appContext.localRegion) {
								setCookie("localRegion", region.Id, { expires: moment().add(2, "weeks").toDate() });
							}
						}

						if (appContext.login && !appContext.login.HomeRegion && region)
							setHomeRegion({ variables: { regionId: region.Id } })
								.then((result) => {
									if (result.data?.setHomeRegion.data) {
										setLogin(result.data?.setHomeRegion.data.Login);
									}
								})
								.catch((err) => {
									console.warn("Error setting home region: ", err);
								});
					}
				});
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [appContext.geoPosition, appContext.login, appContext.regions, ymaps]);

		return <AppContext.Provider value={appContext}>{children}</AppContext.Provider>;
	},
	true,
	["geocode"],
);

const useAppContext = () => {
	const appContext = useContext(AppContext);

	return appContext;
};

export default useAppContext;
export { AppContext, UseAppContextProvider };
