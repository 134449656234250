interface IUploadRequestInit extends RequestInit {
	useUpload?: boolean;
	onProgress?: (this: XMLHttpRequest, ev: ProgressEvent) => any;
	onAbortPossible?: (handler: () => void) => void;
}

export const parseHeaders = (rawHeaders: string): Headers => {
	const headers = new Headers();
	// Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
	// https://tools.ietf.org/html/rfc7230#section-3.2
	const preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, " ");
	preProcessedHeaders.split(/\r?\n/).forEach((line: string) => {
		const parts = line.split(":");
		const key = parts.shift()?.trim();
		if (key) {
			const value = parts.join(":").trim();
			headers.append(key, value);
		}
	});
	return headers;
};

export const uploadFetch = (url: string, options: IUploadRequestInit): Promise<Response> =>
	new Promise<Response>((resolve, reject) => {
		const xhr = new XMLHttpRequest();
		xhr.onload = () => {
			const opts: ResponseInit & { url?: string | null } = {
				status: xhr.status,
				statusText: xhr.statusText,
				headers: parseHeaders(xhr.getAllResponseHeaders() || ""),
			};
			opts.url = "responseURL" in xhr ? xhr.responseURL : (opts.headers as Headers).get("X-Request-URL");
			const body = xhr.response || xhr.responseText;
			resolve(new Response(body, opts));
		};
		xhr.onerror = () => {
			reject(new TypeError("Upload request failed"));
		};
		xhr.ontimeout = () => {
			reject(new TypeError("Upload request timed out"));
		};
		xhr.open(options.method || "POST", url, true);

		if (options.headers) {
			Object.entries(options.headers).forEach(([key, value]) => {
				xhr.setRequestHeader(key, value);
			});
		}

		if (xhr.upload && options.onProgress) {
			xhr.upload.onprogress = options.onProgress;
		}

		if (options.onAbortPossible) {
			options.onAbortPossible(() => {
				xhr.abort();
			});
		}
		xhr.send(options.body);
	});

export const customFetch = (uri: RequestInfo, options: IUploadRequestInit): Promise<Response> => {
	if (options.useUpload && typeof uri === "string") {
		return uploadFetch(uri, options);
	}
	return fetch(uri, options);
};
