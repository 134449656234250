import strings from "./strings";

export function humanFileSize(filesize: number) {
	let size = "";
	if (filesize >= 1048576) {
		size = `${filesize / 1048576} ${strings.mb}`;
	} else if (filesize >= 1024) {
		size = `${filesize / 1024} ${strings.kb}`;
	} else {
		size = `${filesize} ${strings.bytes}`;
	}
	return size;
}
