import { TLocale } from "@rsonav/shared-config";
import { ERecycledType } from "@rsonav/protocol";

export const recycledTypesList: { [key: string]: { localeKey: keyof TLocale } } = {
	[ERecycledType.Paper]: { localeKey: "geo_recycledTypePaper" },
	[ERecycledType.Metall]: { localeKey: "geo_recycledTypeMetall" },
	[ERecycledType.Clothes]: { localeKey: "geo_recycledTypeClothes" },
	//[ERecycledType.Food]: { localeKey: "geo_recycledTypeFood" },
	[ERecycledType.Plastic]: { localeKey: "geo_recycledTypePlastic" },
	[ERecycledType.Wrap]: { localeKey: "geo_recycledTypeWrap" },
	[ERecycledType.Glass]: { localeKey: "geo_recycledTypeGlass" },
	[ERecycledType.Tetrapak]: { localeKey: "geo_recycledTypeTetrapak" },
	[ERecycledType.Dangerous]: { localeKey: "geo_recycledTypeDangerous" },
	[ERecycledType.Battery]: { localeKey: "geo_recycledTypeBattery" },
	[ERecycledType.Thermometer]: { localeKey: "geo_recycledTypeThermometer" },
	[ERecycledType.Lamp]: { localeKey: "geo_recycledTypeLamp" },
	[ERecycledType.Electronics]: { localeKey: "geo_recycledTypeElectronics" },
	[ERecycledType.Huge]: { localeKey: "geo_recycledTypeHuge" },
	[ERecycledType.Other]: { localeKey: "geo_recycledTypeOther" },
};
