import React from "react";
import { Chip } from "@material-ui/core";
import locale from "../../../helpers/strings";
import { IGeoObjectBlockProps } from "./types";
import { badgeConfigs } from "../../../helpers/badgeConfigs";

export const GeoObjectBadge = (props: IGeoObjectBlockProps) => {
	if (!props.geoObject.Type) {
		return null;
	}
	return (
		<Chip
			icon={<div className="badge-dot" />}
			label={locale[badgeConfigs[props.geoObject.Type].labelKey]}
			className={`geo-object-badge ${badgeConfigs[props.geoObject.Type].variant}`}
		/>
	);
};
