import { EGeoObjectType } from "@rsonav/protocol";
import locale from "./strings";

export const geoOrgLabel = (geoObjectType: EGeoObjectType): string => {
	switch (geoObjectType) {
		case EGeoObjectType.Containers:
			return locale.geo_orgContainers;
		case EGeoObjectType.Stationary:
			return locale.geo_orgStationary;
		default:
			return locale.geo_orgTemporary;
	}
};
