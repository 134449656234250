import React, { useEffect, useState } from "react";
import {
	Box,
	Button,
	Grid,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Typography,
} from "@material-ui/core";
import locale from "../../../../helpers/strings";
import useAppContext from "../../../../contexts/AppContext";
import {
	RegionFullFragment,
	useGetAllRegionsQuery,
	UpsertRegionInput,
	GetAllRegionsDocument,
	useUpsertRegionMutation,
} from "@rsonav/protocol";
import { formatPhone, regExps } from "@rsonav/shared-config";
import { FullScreenPlaceholder, StandartTextInput } from "../..";
import _ from "lodash";
import { Controller, useForm } from "react-hook-form";
import { StandartTextInputController } from "../../..";
import { CKEditorInput } from "../../CKEditorInput";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

export interface IRegionsListTabProps {
	onLoading: (loading: boolean) => void;
}

const defaultValues: UpsertRegionInput = {
	Id: "",
	EcoTaxiNumber: "",
	EcoTaxiAbout: "",
	SupportEmail: "",
};
export const RegionsListTab = (props: IRegionsListTabProps) => {
	const { onLoading } = props;

	const { login } = useAppContext();
	const form = useForm<UpsertRegionInput>({
		mode: "onBlur",
		reValidateMode: "onChange",
		defaultValues,
	});

	const { control, errors, handleSubmit, reset } = form;
	const { data, loading } = useGetAllRegionsQuery({
		fetchPolicy: "cache-and-network",
	});

	const [upsertRegion, { loading: upsertingRegion }] = useUpsertRegionMutation({
		refetchQueries: [{ query: GetAllRegionsDocument }],
	});

	const [searchString, setSearchString] = useState<string>("");
	const [selectedRegion, setSelectedRegion] = useState<RegionFullFragment | undefined>(
		login?.HomeRegion || undefined,
	);
	const [filteredRegions, setFilteredRegions] = useState<RegionFullFragment[]>([]);

	useEffect(() => {
		onLoading(loading || upsertingRegion);
	}, [loading, upsertingRegion, onLoading]);

	const regions = data?.getAllRegions;
	useEffect(() => {
		if (!regions) {
			setFilteredRegions([]);
			return;
		}
		const sortedRegions = _.sortBy(regions, ["Name"]);
		if (!searchString.length) {
			setFilteredRegions(sortedRegions);
			return;
		}
		const preparedSearch = searchString.trim().toLowerCase();
		if (!preparedSearch.length) {
			setFilteredRegions(sortedRegions);
		}
		const phoneSearch = preparedSearch.replace(/[^\d]/g, "");

		setFilteredRegions(
			sortedRegions?.filter(
				(r) =>
					r.Name.toLocaleLowerCase().includes(preparedSearch) ||
					r.SupportEmail?.toLocaleLowerCase().includes(preparedSearch) ||
					(phoneSearch.length && r.EcoTaxiNumber?.toLocaleLowerCase().includes(phoneSearch)),
			),
		);
	}, [regions, searchString]);

	useEffect(() => {
		if (!selectedRegion) {
			reset(defaultValues);
		} else {
			reset({
				Id: selectedRegion.Id,
				SupportEmail: selectedRegion.SupportEmail || "",
				EcoTaxiNumber: selectedRegion.EcoTaxiNumber || "",
				EcoTaxiAbout: selectedRegion.EcoTaxiAbout || "",
			});
		}
	}, [reset, selectedRegion]);

	if (!login) {
		return null;
	}

	const submit = (formData: UpsertRegionInput) => {
		const data: UpsertRegionInput = {
			Id: formData.Id,
		};
		const supportEmail = formData.SupportEmail?.trim();
		data.SupportEmail = supportEmail || null;

		const ecoTaxiAbout = formData.EcoTaxiAbout?.trim();
		data.EcoTaxiAbout = ecoTaxiAbout || null;

		const ecoTaxiNumber = formData.EcoTaxiNumber?.trim().replace(/[^\d+]/g, "");
		data.EcoTaxiNumber = ecoTaxiNumber?.length === 12 ? ecoTaxiNumber : null;

		upsertRegion({
			variables: { data },
			awaitRefetchQueries: true,
		});
	};

	return (
		<Grid container spacing={2} className="region-container">
			<Grid item xs={12} md={4} className="left-col">
				<Typography variant="h4">{locale.pickRegion}</Typography>
				<StandartTextInput
					value={searchString}
					onChange={setSearchString}
					label={locale.search}
					margin="dense"
					className="search-input"
					fullWidth
				/>
				<Box className="region-list-container">
					{filteredRegions?.length ? (
						<List component="nav" className="region-list">
							{filteredRegions.map((region) => (
								<ListItem
									key={region.Id}
									button
									selected={selectedRegion?.Id === region.Id}
									onClick={() => setSelectedRegion(region)}
								>
									<ListItemText
										primary={region.Name}
										secondary={
											<Typography variant="caption">
												{locale.support_title}:{" "}
												{region.SupportEmail ? (
													<Typography variant="subtitle1" color="primary" display="inline">
														{region.SupportEmail}
													</Typography>
												) : (
													<Typography variant="subtitle1" color="secondary" display="inline">
														{locale.notSet}
													</Typography>
												)}
												, {locale.aboutTaxi_name}:{" "}
												{region.EcoTaxiNumber ? (
													<Typography variant="subtitle1" color="primary" display="inline">
														{formatPhone(region.EcoTaxiNumber)}
													</Typography>
												) : (
													<Typography variant="subtitle1" color="secondary" display="inline">
														{locale.notSet}
													</Typography>
												)}
											</Typography>
										}
									/>
									{selectedRegion?.Id === region.Id && (
										<ListItemSecondaryAction>
											<ChevronRightIcon color="action" />
										</ListItemSecondaryAction>
									)}
								</ListItem>
							))}
						</List>
					) : (
						<FullScreenPlaceholder titleKey={loading ? "loading" : "nothingFound"} />
					)}
				</Box>
			</Grid>
			<Grid item xs={12} md={8} className="right-col">
				<Typography variant="h4">
					{locale.region}: {selectedRegion?.Name || locale.notSet}
				</Typography>
				<Grid container spacing={2} className="edit-container">
					<Grid item xs={12} sm={6}>
						<Controller name="Id" defaultValue={null} control={control} render={(_props) => <></>} />
						<StandartTextInputController
							name="SupportEmail"
							control={control}
							errorMessage={errors.SupportEmail?.message}
							label={locale.support_email}
							margin="normal"
							rules={{
								pattern: {
									value: regExps.email,
									message: locale.wrongEmail,
								},
							}}
							placeholder="example@mail.com"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<StandartTextInputController
							name="EcoTaxiNumber"
							control={control}
							errorMessage={errors.EcoTaxiNumber?.message}
							label={locale.aboutTaxi_phone}
							margin="normal"
							rules={{
								pattern: {
									value: regExps.phoneOptional,
									message: locale.phoneError,
								},
							}}
							placeholder="+7 (999) 999 99 99"
							mask="+7 (999) 999 99 99"
						/>
					</Grid>
					<Grid item xs={12} className="about">
						<Controller
							name="EcoTaxiAbout"
							control={control}
							render={({ value, onChange, onBlur }) => (
								<CKEditorInput
									value={value}
									onChange={onChange}
									onBlur={onBlur}
									label="aboutTaxi_title"
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12}>
						<Button
							autoFocus
							onClick={handleSubmit(submit)}
							color="primary"
							variant="contained"
							className="save-button"
						>
							{locale.save}
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
