import { FormControlLabel, Checkbox, CheckboxProps, FormControlLabelProps } from "@material-ui/core";
import { TLocale } from "@rsonav/shared-config";
import React from "react";
import locale from "../../helpers/strings";

export interface IStandartCheckboxProps extends Omit<CheckboxProps, "icon" | "checkedIcon"> {
	labelProps?: Omit<FormControlLabelProps, "label" | "control">;
	labelKey?: keyof TLocale;
	label?: React.ReactNode;
}

export const StandartCheckbox = (props: IStandartCheckboxProps) => {
	const { labelKey, label, labelProps, ...checkboxProps } = props;
	const className = `standart-checkbox ${props.color || ""} ${labelProps?.className || ""}`;
	return (
		<FormControlLabel
			{...labelProps}
			control={
				<Checkbox
					{...checkboxProps}
					icon={<i className="icon icon-check" />}
					checkedIcon={<i className="icon icon-check" />}
				/>
			}
			label={label || (labelKey && locale[labelKey])}
			className={className}
		/>
	);
};
