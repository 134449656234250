import React from "react";
import { Grid } from "@material-ui/core";
import withAuth from "../helpers/withAuth";
import { StandartView } from "../components";

export const Home = withAuth(() => {
	return (
		<StandartView>
			<Grid container justify="center" alignItems="center">
				<Grid item justify="center" alignItems="center">
					<img src="/images/logo.png" width={256} alt="logo" />
				</Grid>
			</Grid>
		</StandartView>
	);
});
