import React from "react";
import { Typography } from "@material-ui/core";
import { IGeoObjectBlockProps } from "./types";

export const Address = (props: IGeoObjectBlockProps) => {
	if (!props.geoObject.Type) {
		return null;
	}
	return (
		<Typography variant="h4">{`${props.geoObject.City ? `${props.geoObject.City}, ` : ""}${
			props.geoObject.Address
		}`}</Typography>
	);
};
