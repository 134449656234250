import React from "react";
import { Control, Controller, ControllerProps } from "react-hook-form";
import { StandartTextInput, TStandartTextInputProps } from "../common";

type TProps = Pick<ControllerProps<React.ReactElement, Control>, "control" | "name" | "rules"> &
	Omit<TStandartTextInputProps, "value" | "onChange">;

export function StandartTextInputController(props: TProps): JSX.Element {
	const { name, control, rules, ...textIputProps } = props;
	if (rules?.required) {
		textIputProps.label = textIputProps.label + " *";
	}

	return (
		<Controller
			name={name}
			control={control}
			rules={rules}
			render={({ onChange, value }) => (
				<StandartTextInput
					{...textIputProps}
					error={!!textIputProps.errorMessage}
					value={value}
					onChange={onChange}
				/>
			)}
		/>
	);
}
