import React from "react";

import { IGeoObjectBlockProps } from "./types";
import locale from "../../../helpers/strings";
import { Box, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import useAppContext from "../../../contexts/AppContext";

export const SupportButton = (props: IGeoObjectBlockProps): JSX.Element | null => {
	const history = useHistory();
	const { login, curRegion, localRegion } = useAppContext();

	const homeRegion = login?.HomeRegion || localRegion || curRegion;
	if (!homeRegion?.SupportEmail) {
		return null;
	}

	const onClick = () => {
		history.push(`/support/geo/${props.geoObject.Id}`, { object: props.geoObject });
	};
	return (
		<Box className="info-block">
			<Button className="support-button" onClick={onClick} fullWidth variant="contained" color="default">
				{locale.geo_supportButton}
			</Button>
		</Box>
	);
};
