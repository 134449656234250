import { Box, IconButton, Typography } from "@material-ui/core";
import { TLocale } from "@rsonav/shared-config";
import React, { useEffect, useState } from "react";
import locale from "../../../helpers/strings";

export interface IContainersCountProps {
	onChange: (value: number) => void;
	value: number;
	titleKey: keyof TLocale;
}

export const ContainersCount = (props: IContainersCountProps): JSX.Element => {
	const [value, setValue] = useState(props.value || 0);

	useEffect(() => {
		props.onChange(value);
	}, [props, value]);

	useEffect(() => {
		setValue(props.value);
	}, [props.value]);

	return (
		<Box className="containers-count">
			<Typography variant="body2">{locale[props.titleKey]}</Typography>
			<Box className="controls-container">
				<IconButton onClick={() => setValue((prevValue) => prevValue - 1)} disabled={value <= 0}>
					<i className="icon icon-minus" />
				</IconButton>
				<Typography variant="h4">{value}</Typography>
				<IconButton onClick={() => setValue((prevValue) => prevValue + 1)}>
					<i className="icon icon-plus-nocircle" />
				</IconButton>
			</Box>
		</Box>
	);
};
