import React from "react";
import { FavoriteFullFragment } from "@rsonav/protocol";
import { Box, Grid, Typography } from "@material-ui/core";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import locale from "../../helpers/strings";
import { recycledTypesList } from "../../helpers/recycledTypesList";
import { getDistanceFormated } from "@rsonav/shared-config";

export interface IFavoritesListItemProps {
	favorite?: FavoriteFullFragment;
}

export const FavoritesListItem = (props: IFavoritesListItemProps) => {
	if (!props.favorite) {
		return (
			<Grid item className="favorites-item placeholder">
				<Box className="container">
					<Box className="header">
						<Typography variant="body2">
							<Skeleton />
						</Typography>
						<Typography variant="body2">
							<Skeleton />
						</Typography>
					</Box>
					<Typography variant="subtitle1">
						<Skeleton />
					</Typography>
				</Box>
			</Grid>
		);
	}

	return (
		<Grid item className="favorites-item">
			<Link to={`/favorites/geo/${props.favorite.GeoObject.Id}`}>
				<Box className="container">
					<Box className="header">
						<Typography variant="h5">{props.favorite.GeoObject.Address}</Typography>
						{!!props.favorite.GeoObject.Distance && (
							<Typography variant="body2" className="distance">
								{getDistanceFormated(props.favorite.GeoObject.Distance)}
							</Typography>
						)}
					</Box>
					{props.favorite.GeoObject.RecycledType.length > 0 && (
						<Typography variant="subtitle1">
							{props.favorite.GeoObject.RecycledType.map(
								(type) => locale[recycledTypesList[type.Type].localeKey],
							).join(", ")}
						</Typography>
					)}
				</Box>
			</Link>
		</Grid>
	);
};
