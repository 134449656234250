import React from "react";
import { Link, Typography } from "@material-ui/core";
import { StandartView } from "../components";
import locale from "../helpers/strings";
import { useGetOneArticleQuery } from "@rsonav/protocol";
import { ABOUT_ID } from "@rsonav/shared-config";

export const About = () => {
	const { data, loading } = useGetOneArticleQuery({ variables: { id: ABOUT_ID } });

	const article = data?.getOneArticle;

	if (loading) {
		return <StandartView header={<Typography variant="h4">{locale.about_title}</Typography>}></StandartView>;
	}
	return (
		<StandartView header={<Typography variant="h4">{locale.about_title}</Typography>}>
			{article?.Body.length ? (
				<div className="article-content" dangerouslySetInnerHTML={{ __html: article.Body }} />
			) : (
				<>
					<Typography variant="body2" gutterBottom>
						{locale.about_text1}
					</Typography>
					<Typography variant="body2" gutterBottom>
						{locale.about_text2}
					</Typography>
					<Typography variant="body2" gutterBottom>
						{locale.about_text3}
					</Typography>
					<ul>
						<Typography variant="body2" component="li" gutterBottom>
							{locale.about_list1}
						</Typography>
						<Typography variant="body2" component="li" gutterBottom>
							{locale.about_list2}
						</Typography>
						<Typography variant="body2" component="li" gutterBottom>
							{locale.about_list3}
						</Typography>
						<Typography variant="body2" component="li" gutterBottom>
							{locale.about_list4}
						</Typography>
					</ul>
					<Typography variant="body2" gutterBottom>
						{locale.about_creators}
						<Link variant="body2" target="_blank" rel="noreferrer" href="http://voop37.ru/">
							{locale.about_creatorsLink}
						</Link>
					</Typography>
					<Typography variant="body2" gutterBottom>
						<Link variant="body2" target="_blank" rel="noreferrer" href="https://vk.com/rso.navigator">
							{locale.about_vk}
						</Link>
					</Typography>
					<img className="full-width" src="/pgrants_logo_gp_horizontal.png" alt={locale.about_pgLogoAlt} />
				</>
			)}
		</StandartView>
	);
};
