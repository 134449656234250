import React, { useCallback, useEffect, useMemo, useRef } from "react";
import {
	ArticleFullFragment,
	useUpsertArticleMutation,
	EArticleType,
	defaultUpsertArticleInput,
	GetManyArticlesDocument,
	useGetOneArticleLazyQuery,
	AttachmentFullFragment,
	EAttachmentType,
} from "@rsonav/protocol";
import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	Grid,
	IconButton,
	MenuItem,
	TextField,
	Typography,
} from "@material-ui/core";
import locale from "../../helpers/strings";
import { Close } from "@material-ui/icons";
import { Controller, useForm } from "react-hook-form";
import useAppContext from "../../contexts/AppContext";
import { mergeKeepShape, TLocale } from "@rsonav/shared-config";
import { Gallery, StandartTextInputController } from "../../components";
import { CKEditorInput } from "../../components/common/CKEditorInput";

export interface IUpsertArticleDialogProps {
	open: boolean;
	onClose: () => void;
	article?: Partial<ArticleFullFragment>;
}

type TUpsertArticleFormData = {
	header: string;
	body: string;
	important: boolean;
	type: EArticleType;
	attachmentId: string[];
	pushText: string;
	regionId: string;
};

const articleTypes: Array<{ value: EArticleType; label: keyof TLocale }> = [
	{
		value: EArticleType.News,
		label: "news_typeNews",
	},
	{
		value: EArticleType.Help,
		label: "news_typeHelp",
	},
	{
		value: EArticleType.Law,
		label: "news_typeLaw",
	},
];

const defaultFormValues: TUpsertArticleFormData = {
	header: "",
	body: "",
	important: false,
	type: EArticleType.News,
	attachmentId: [],
	pushText: "",
	regionId: "-1",
};

export const UpsertArticleDialog = (props: IUpsertArticleDialogProps) => {
	const { article, open, onClose } = props;

	const { login, regions } = useAppContext();
	const [upsertArticle] = useUpsertArticleMutation();
	const [getArticle, { data }] = useGetOneArticleLazyQuery({
		fetchPolicy: "cache-and-network",
	});

	const attachments = useRef<AttachmentFullFragment[]>([]);
	const onUpdateAttachments = useCallback(
		(newAttachments: AttachmentFullFragment[]) => (attachments.current = newAttachments),
		[],
	);

	const promoAttachment = useRef<AttachmentFullFragment>();
	const onUpdatePromo = useCallback(
		(newAttachments: AttachmentFullFragment[]) => (promoAttachment.current = newAttachments[0]),
		[],
	);

	const regionsOptions = useMemo(() => {
		const options: Array<{ value: string; label: string }> = [{ value: "-1", label: locale.allRegions }];
		if (regions) {
			for (const region of regions) {
				options.push({ value: region.Id, label: region.Name });
			}
		}
		return options;
	}, [regions]);

	useEffect(() => {
		if (article?.Id) {
			getArticle({ variables: { id: article.Id } });
		}
	}, [getArticle, article]);

	const form = useForm<TUpsertArticleFormData>({
		mode: "onBlur",
		reValidateMode: "onChange",
		defaultValues: defaultFormValues,
	});
	const { handleSubmit, errors, control, reset } = form;

	const fetchedArticle = article?.Id ? data?.getOneArticle : undefined;

	useEffect(() => {
		if (!fetchedArticle) {
			return;
		}
		form.setValue("header", fetchedArticle.Header);
		form.setValue("body", fetchedArticle.Body);
		form.setValue("important", fetchedArticle.Important);
		form.setValue("type", fetchedArticle.Type);
		form.setValue("regionId", fetchedArticle.Region?.Id);
	}, [form, fetchedArticle]);

	useEffect(() => {
		if (!login || !regionsOptions) {
			return;
		}
		reset({ ...defaultFormValues, regionId: login.HomeRegion?.Id || "-1" });
	}, [reset, login, regionsOptions]);

	const submit = useCallback(
		async (data: TUpsertArticleFormData) => {
			const attachmentId = attachments.current.map((a) => a.Id);
			promoAttachment.current && attachmentId.push(promoAttachment.current.Id);
			const upsertArticleInput = mergeKeepShape(defaultUpsertArticleInput, {
				...fetchedArticle,
				Header: data.header,
				Body: data.body,
				Important: data.important,
				Type: data.type,
				AttachmentId: attachmentId,
				PushText: data.pushText.length ? data.pushText : undefined,
				RegionId: data.regionId !== "-1" ? data.regionId : undefined,
			});

			await upsertArticle({
				variables: { data: upsertArticleInput },
				refetchQueries: [
					{
						query: GetManyArticlesDocument,
						variables: { input: { Pagination: { Limit: 50, Offset: 0 } } },
					},
				],
			});

			onClose();
		},
		[fetchedArticle, onClose, upsertArticle],
	);

	if (!article || !login) {
		return null;
	}

	return (
		<Dialog onClose={onClose} className="upsert-article-dialog" open={open} maxWidth="xl" fullWidth>
			<DialogTitle>
				<Grid container justify="space-between" alignItems="center">
					<Typography variant="h6">{locale.news_upsertTitle}</Typography>
					<IconButton aria-label="close" onClick={onClose}>
						<Close />
					</IconButton>
				</Grid>
			</DialogTitle>
			<DialogContent dividers>
				<Grid container spacing={2}>
					<Grid item xs={12} md={8} lg={9}>
						<Grid container spacing={2} alignItems="center">
							<Grid item xs={8}>
								<Controller
									name="type"
									control={control}
									rules={{ required: locale.required }}
									render={({ onChange, value }) => (
										<TextField
											label={locale.news_upsertArticleType}
											select
											value={value}
											onChange={onChange}
											variant="filled"
											fullWidth
										>
											{articleTypes.map((option) => (
												<MenuItem key={option.value} value={option.value}>
													{locale[option.label]}
												</MenuItem>
											))}
										</TextField>
									)}
								/>
							</Grid>
							<Grid item xs={4}>
								<Controller
									name="important"
									control={control}
									render={({ onChange, value }) => (
										<FormControlLabel
											control={
												<Checkbox
													checked={value}
													onChange={(_evt, checked) => onChange(checked)}
													color="primary"
												/>
											}
											label={locale.news_upsertArticleImportant}
										/>
									)}
								/>
							</Grid>
						</Grid>
						<StandartTextInputController
							name="header"
							control={control}
							rules={{
								required: locale.required,
							}}
							errorMessage={errors.header?.message}
							label={locale.news_upsertArticleTitle}
							margin="normal"
						/>
						<StandartTextInputController
							name="pushText"
							control={control}
							rules={{
								maxLength: { value: 150, message: locale.maxLength },
							}}
							errorMessage={errors.pushText?.message}
							label={locale.news_pushTextLabel}
							margin="normal"
						/>
						<Controller
							name="body"
							control={control}
							rules={{
								required: locale.required,
							}}
							render={({ value, onChange, onBlur }) => (
								<CKEditorInput
									value={value}
									onChange={onChange}
									onBlur={onBlur}
									label="news_upsertArticleBody"
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={4} lg={3}>
						<Controller
							name="regionId"
							control={control}
							render={({ onChange, value, ref }) => {
								return (
									<TextField
										label={locale.region}
										select
										value={value}
										onChange={onChange}
										variant="filled"
										fullWidth
										inputRef={ref}
									>
										{regionsOptions.map((option) => (
											<MenuItem key={option.value} value={option.value}>
												{option.label}
											</MenuItem>
										))}
									</TextField>
								);
							}}
						/>

						<Gallery
							attachmentType={EAttachmentType.ArticlePromo}
							maxItems={1}
							acceptedFiles={["image/*"]}
							onUpdate={onUpdatePromo}
							objectId={article.Id}
							maxFileSize={2097152}
							addFileTip="news_upsertAddImage"
							label="news_upsertPromoLabel"
						/>
						<Gallery
							attachmentType={EAttachmentType.ArticleContent}
							maxItems={10}
							acceptedFiles={["image/*"]}
							onUpdate={onUpdateAttachments}
							objectId={article.Id}
							maxFileSize={2097152}
							addFileTip="news_upsertAddImage"
							label="news_upsertImagesLabel"
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button autoFocus onClick={handleSubmit(submit)} color="primary" variant="contained">
					{locale.save}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
